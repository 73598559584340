import { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Modal, ModalBody, ModalFooter, Button } from "reactstrap"

import Swal from "sweetalert2"

import { useDeleteShiftMutation } from "../shift-slices/shiftsApiSlice"

import { alerts } from "../../utils/alerts"
import { months } from "../../consts/consts"

import {
  AMOUNT_OF_HOURS,
  ATTENTION,
  CLOSE,
  DESCRIPTION,
  HOURLY_RATE,
  IN_TOTAL,
  LUNCH_HOURS,
  LUNCH_HOURS_PAID,
  NIGHT_SHIFT_BEGINS_AT,
  NIGHT_SHIFT_CONTINUOUS,
  NIGHT_SHIFT_ENDS_AT,
  NIGHT_SHIFT_HOURS,
  NIGHT_SHIFT_MULTIPLIER,
  NIGHT_SHIFT_MULTIPLIER_ELIGIBLE,
  NO,
  NORMAL_HOURS_BEFORE_OVERTIME,
  OVERTIME_HOURS,
  OVERTIME_MULTIPLIER,
  OVERTIME_MULTIPLIER_ELIGIBLE,
  SHIFT_ENDED,
  SHIFT_SALARY,
  SHIFT_STARTED,
  YES
} from "../../../assets/texts/texts"

import { CONFIRM_DELETE_SHIFT_TEXT } from "../../../assets/texts/alertTexts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faList, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { translateError } from "../../utils/translateErrorCodes"

const Shift = ({ isUser, shift, language, refetchShifts }) => {
  const [modalOpened, setModalOpened] = useState(false)

  const [
    deleteShift,
    {
      isLoading,
      isSuccess,
      isError,
      error,
    },
  ] = useDeleteShiftMutation()

  useEffect(() => {
    if (isSuccess) {
      setModalOpened(false)
      refetchShifts()
      return
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) alerts.errorAlert(translateError({ language, code: error?.data?.code }))
  }, [isError])

  if ((!shift?.hoursWorked && shift?.hoursWorked !== 0) || !language || isLoading) return

  const handleDeleteShift = (day) => {
    Swal.fire({
      icon: "warning",
      title: ATTENTION[language],
      text: CONFIRM_DELETE_SHIFT_TEXT[language] + day,
      showCancelButton: true,
      confirmButtonText: YES[language],
      cancelButtonText: NO[language],
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteShift({ id: shift?.id })
      }
    })
  }

  return (
    <>
      <Modal
        isOpen={modalOpened}
        toggle={() => {
          setModalOpened((prev) => !prev)
        }}
        keyboard={true}
        backdrop={true}
      >
        <div style={{ position: "relative", right: "2vw", top: "2vh" }}>
          <button
            style={{ cursor: "pointer", width: "40px", height: "40px" }}
            className="close"
            onClick={() => {
              setModalOpened((prev) => !prev)
            }}
            type="button"
          >
            &times;
          </button>
        </div>
        <ModalBody>
          <Row>
            <Col>
              <b>{SHIFT_STARTED[language]}:</b>
              {' '}
              {shift?.startDay}
              {' '}
              {months[shift?.startMonth][language]}
              {' '}
              {shift?.startYear}
              {' '}
              {shift?.startTime}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{SHIFT_ENDED[language]}:</b>
              {' '}
              {shift?.endDay}
              {' '}
              {months[shift?.endMonth][language]}
              {' '}
              {shift?.endYear}
              {' '}
              {shift?.endTime}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{IN_TOTAL[language]}:</b> {shift?.hoursWorked?.toFixed(2)} {AMOUNT_OF_HOURS[language]}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{NORMAL_HOURS_BEFORE_OVERTIME[language]}:</b> {shift?.normalHours?.toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{OVERTIME_HOURS[language]}:</b> {shift?.overTimeHours?.toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{NIGHT_SHIFT_HOURS[language]}:</b> {shift?.nightShiftHours?.toFixed(2)}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{LUNCH_HOURS_PAID[language]}:</b> {shift?.lunchPaid === true ? YES[language] : NO[language]}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{LUNCH_HOURS[language]}:</b> {(shift?.lunchHours * 60).toFixed(0)}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{OVERTIME_MULTIPLIER_ELIGIBLE[language]}:</b>
              {' '}
              {shift?.overTimeMultiplierEligible === true ? YES[language] : NO[language]}
            </Col>
          </Row>
          {shift?.overTimeMultiplierEligible === false ? null :
            <>
              <Row>
                <Col>
                  <b>{OVERTIME_MULTIPLIER[language]}:</b> {(shift?.overTimeMultiplier * 100)?.toFixed(0)}
                </Col>
              </Row>
            </>
          }
          <Row>
            <Col>
              <b>{NIGHT_SHIFT_MULTIPLIER_ELIGIBLE[language]}:</b>
              {' '}
              {shift?.nightShiftMultiplierEligible === true ? YES[language] : NO[language]}
            </Col>
          </Row>
          {shift?.nightShiftMultiplierEligible === false ? null :
            <>
              <Row>
                <Col>
                  <b>{NIGHT_SHIFT_MULTIPLIER[language]}:</b> {(shift?.nightShiftMultiplier * 100)?.toFixed(0)}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>{NIGHT_SHIFT_CONTINUOUS[language]}:</b> {shift?.nightShiftContinuous === true ? YES[language] : NO[language]}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>{NIGHT_SHIFT_BEGINS_AT[language]}:</b> {shift?.nightShiftBeginsAt}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>{NIGHT_SHIFT_ENDS_AT[language]}:</b> {shift?.nightShiftEndsAt}
                </Col>
              </Row>
            </>
          }
          <Row>
            <Col>
              <b>{HOURLY_RATE[language]}:</b> {shift?.normalRate?.toFixed(2)} {shift?.currency}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{SHIFT_SALARY[language]}:</b> {shift?.shiftSalary?.toFixed(2)} {shift?.currency}
            </Col>
          </Row>
          {!shift?.description?.length ? null :
            <Row>
              <Col>
                <b>{DESCRIPTION[language]}:</b> {shift?.description}
              </Col>
            </Row>
          }
        </ModalBody>
        <ModalFooter>
          {isUser !== true ? null :
            <Button
              style={{ backgroundColor: '#ed2939', color: 'white' }}
              onClick={() => handleDeleteShift(shift.startDay)}
            >
              <FontAwesomeIcon
                icon={faTrashCan}
                color="white"
              />
            </Button>
          }
          {isUser !== false ? null :
            <Button
              onClick={() => setModalOpened((prev) => !prev)}
            >
              {CLOSE[language]}
            </Button>
          }
        </ModalFooter>
      </Modal>
      <Card>
        <CardBody>
        <Row>
          <Col>
            <Row>
              <Col>
                {months[shift.startMonth][language]} {shift.startDay}.
              </Col>
              <Col>
                {shift.hoursWorked?.toFixed(2)} {AMOUNT_OF_HOURS[language]}
              </Col>
            </Row>
          </Col>
          <Col className="text-center">
            {shift.shiftSalary.toFixed(2)} {shift.currency}
          </Col>
          <Col className="text-right">
            <Button
              style={{ backgroundColor: '#0099da', color: 'white' }}
              onClick={() => setModalOpened((prev) => !prev)}
            >
              <FontAwesomeIcon
                icon={faList}
                color="white"
              />
            </Button>
          </Col>
        </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default Shift
