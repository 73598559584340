import { Routes, Route } from "react-router-dom"

import Login from "./features/auth/Login"
import Layout from "./components/Layout"
import useTitle from "./hooks/useTitle"
import PersistLogin from "./features/auth/PersistLogin"
import NewUserForm from "./features/users/create-user/NewUserForm"
import RequireAuth from "./features/auth/RequireAuth"
import NewShiftForm from "./features/shifts/create-shift/NewShiftForm"
import UserShifts from "./features/shifts/view-shifts/UserShifts"
import TimerShift from "./features/shifts/timer-shift/TimerShift"
import UserInfo from "./features/users/user-info/UserInfo"
import RequireUserAuth from "./features/auth/RequireUserAuth"
import RequireCompanyAuth from "./features/auth/RequireCompanyAuth"
import CompanyUsersList from "./features/users/CompanyUsersList"
import TermsConditions from "./components/TermsConditions"
import { selectCurrentLanguage } from "./features/language/languageSlice"
import { useSelector } from "react-redux"
import { TIMETABLES } from "./assets/texts/texts"
import { useEffect } from "react"
import NoPage from "./components/NoPage"

const App = () => {
  const language = useSelector(selectCurrentLanguage)

  useEffect(() => {
  }, [language])

  const hasTouch = () => {
    return (
      "ontouchstart" in document.documentElement ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    )
  }

  if (hasTouch()) {
    try {
      for (const si in document.styleSheets) {
        const styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (let ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue

          if (styleSheet.rules[ri].selectorText.match(":hover")) {
            styleSheet.deleteRule(ri)
          }
        }
      }
    } catch (ex) {}
  }

  useTitle(TIMETABLES[language])

  return (
    <Routes>
      <Route element={<PersistLogin />}>
        <Route path="/" element={<Layout />}>
          <Route element={<RequireAuth />}>
            <Route index element={<TimerShift />} />
            <Route path="/edit-profile" element={<UserInfo />} />
            <Route path="/shifts" element={<UserShifts />} />
            <Route element={<RequireUserAuth />}>
              <Route path="/new-shift" element={<NewShiftForm />} />
            </Route>
            <Route element={<RequireCompanyAuth />}>
              <Route path="/companyusers" element={<CompanyUsersList />} />
            </Route>
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<NewUserForm />} />
          <Route path="policy" element={<TermsConditions />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default App
