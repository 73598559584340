import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'

import Swal from 'sweetalert2'

import useAuth from '../../hooks/useAuth'

import { setSelectedCompanyUser } from './user-slices/companyUsersApiSlice'

import { useRemoveEmployeeMutation } from './user-slices/usersApiSlice'

import { ATTENTION, NO, YES } from '../../assets/texts/texts'
import { CONFIRM_DELETE_USER_TEXT, DELETE_USER_WARNING_TEXT } from '../../assets/texts/alertTexts'

import { alerts } from '../utils/alerts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { translateError } from '../utils/translateErrorCodes'

const CompanyUser = ({ user, language, refetchUsers }) => {
  const auth = useAuth()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [removeEmployee, { isLoading, isSuccess, isError, error }] = useRemoveEmployeeMutation()

  useEffect(() => {
    if (isSuccess) {
      refetchUsers()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) alerts.errorAlert(translateError({ language, code: error?.data?.code }))
  }, [isError])

  if (isLoading) return

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              {user?.name} ({user?.username})
            </Col>
            <Col className='text-right'>
              <Button
                type='button'
                style={{ backgroundColor: '#0099da', color: 'white' }}
                onClick={() => {
                  dispatch(setSelectedCompanyUser(user))
                  navigate('/shifts')
                }}
              >
                <FontAwesomeIcon
                  color='white'
                  icon={faList}
                />
              </Button>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col className='text-right'>
              <Button
                type='button'
                style={{ backgroundColor: '#ed2939', color: 'white' }}
                onClick={() => {
                  Swal.fire({
                    icon: "warning",
                    title: ATTENTION[language],
                    text: CONFIRM_DELETE_USER_TEXT[language] + `${user?.name} (${user?.username}). ` + DELETE_USER_WARNING_TEXT[language],
                    showCancelButton: true,
                    confirmButtonText: YES[language],
                    cancelButtonText: NO[language],
                    reverseButtons: true,
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      await removeEmployee({ userId: user?.id, companyId: auth?.userId })
                    }
                  });
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashCan}
                  color='white'
                />
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default CompanyUser
