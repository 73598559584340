import { PDFDocument } from "pdf-lib"
import * as XLSX from 'xlsx'

import { months } from "../consts/consts"

import {
  AMOUNT_OF_HOURS,
  DATE,
  HOURS,
  LUNCH,
  LUNCH_TIMES,
  NIGHT_SHIFT_HOURS,
  OF_WHICH,
  OVERTIME_HOURS,
  PDF_WEEK,
  SALARY,
  START_TIME
} from "../../assets/texts/texts"

export const getWeekNumber = (date) => {
  const targetDate = new Date(date)
  targetDate.setDate(targetDate.getDate() + 4 - (targetDate.getDay() || 7))
  const yearStart = new Date(targetDate.getFullYear(), 0, 1)
  const weekNumber = Math.ceil((((targetDate - yearStart) / 86400000) + 1) / 7)

  return weekNumber
}

export const calculateHoursWorked = (startDate, startTime, endDate, endTime) => {
  const startDateTime = new Date(
    `${startDate.toLocaleDateString('en-CA')}T${startTime}`
  )

  const endDateTime = new Date(
    `${endDate.toLocaleDateString('en-CA')}T${endTime}`
  )

  const diffInMilliseconds = endDateTime - startDateTime
  const diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2)

  return diffInHours
}

export const calculateNightShiftHours = (
  startDate, startTime, nightShiftStartTime, nightShiftEndTime, hoursWorked, nightShiftContinuous
) => {
  const startDateTime = new Date(
    `${startDate.toISOString().split('T')[0]}T${startTime}`
  )

  const nightShiftStartDateTime = new Date(
    `${startDate.toISOString().split('T')[0]}T${nightShiftStartTime}`
  )

  const nightShiftEndDateTime = new Date(
    `${startDate.toISOString().split('T')[0]}T${nightShiftEndTime}`
  )

  // millisecond difference between shift start and night shift start
  const diffInMilliseconds = nightShiftStartDateTime - startDateTime >= 0 
    ? nightShiftStartDateTime - startDateTime
    : nightShiftStartDateTime - startDateTime + 24 * 60 * 60 * 1000

  // millisecond difference between night shift begin and end time
  const diffInNightShiftMilliseconds = nightShiftEndDateTime - nightShiftStartDateTime >= 0 
    ? nightShiftEndDateTime - nightShiftStartDateTime
    : nightShiftEndDateTime - nightShiftStartDateTime + 24 * 60 * 60 * 1000

  // hours until night shift starts
  const hoursUntilNightShiftStarts = parseInt((diffInMilliseconds / (1000 * 60 * 60)).toFixed(2))
  // night shift hours each night
  const nightShiftHours = parseInt((diffInNightShiftMilliseconds / (1000 * 60 * 60)).toFixed(2))

  // you are in the current night shift if the hours until shift starts is greater than 24h - night shift hours
  // if NS ends 6am, shift started 5am, NS starts 22, hours worked was 50, then:
  // hoursUntilNightShiftStarts = 17
  // nightShiftHours = 8
  // 17 - (24 - 8)) = 1
  const hoursWorkedBeforeEndOfNS = hoursUntilNightShiftStarts - (24 - nightShiftHours)

  // if night shift starts after you finish
  if (hoursWorked <= hoursUntilNightShiftStarts) {
    if (hoursWorked > 24 - nightShiftHours) { // if you started when NS was already in effect
      if (nightShiftContinuous === true) { // all the hours will be night shift hours
        return hoursWorked
      }
      const hoursFromStart = hoursWorked - (24 - nightShiftHours)

      return hoursFromStart
    }
    return 0
  }
  // if you work into the night shift and it's continuous, all the hours after reaching night shift are NS hours
  // TODO: what if you started when night shift was in effect
  else if ((hoursWorked - hoursUntilNightShiftStarts <= nightShiftHours) || nightShiftContinuous === true) {
    if (hoursWorkedBeforeEndOfNS > 0) {
      if (nightShiftContinuous) return hoursWorked

      return hoursWorkedBeforeEndOfNS + hoursWorked - hoursUntilNightShiftStarts
    }
    return hoursWorked - hoursUntilNightShiftStarts
  } else { // you worked into the night shift and night shift is not continuous
    // hours since night shift started
    const hoursSinceNightShiftStarted = hoursWorked - hoursUntilNightShiftStarts
    // how many full (24h) days did you work since night shift started
    const daysLeft = Math.floor(hoursSinceNightShiftStarted / 24)
    // hours for full days (2 days left * 8 hours of NS hours a day = 16 fullDayNightsHours)
    const fullDayNightsHours = daysLeft * nightShiftHours
    // how many hours are still left after the full days, 50 hours since NS started = 2 hours unaccounted for
    const hoursAfter = hoursSinceNightShiftStarted % 24
    // with 2 hours left, and NS being 8 hours, keep the 2 hours, if it's 11 hours left, you get 8
    const extraHours = hoursAfter <= nightShiftHours ? hoursAfter : nightShiftHours
    // return the 16h + either 2 or 8 hours
    if (hoursWorkedBeforeEndOfNS > 0) {      
      return hoursWorkedBeforeEndOfNS + fullDayNightsHours + extraHours
    }
    return fullDayNightsHours + extraHours
  }
}

export const formatMaxTwoDecimals = (number) => {
  // Convert the float to a string
  const numberString = number.toString()

  // Find the position of the decimal point
  const decimalIndex = numberString.indexOf('.')

  // If there is no decimal point, return the number as is
  if (decimalIndex === -1) {
    return number
  }

  // Check the number of digits after the decimal point
  const decimalDigits = numberString.length - decimalIndex - 1

  // If there are more than 2 decimal points, return the number fixed to 2 decimal places
  if (decimalDigits > 2) {
    return parseFloat(number.toFixed(2))
  }

  // Otherwise, return the number as is
  return number
}

export const createEmployeeShiftsPDF = async (
  shifts, employeeName, employeeUsername, isMonthly, timeframe, year, hours, language, salary
) => {
  const pdfDoc = await PDFDocument.create()
  let page = pdfDoc.addPage()
  const { height } = page.getSize()

  let yPosition = height - 100 // Starting Y position for text

  const addNewPage = () => {
    page = pdfDoc.addPage()
    yPosition = height - 50 // Reset Y position for the new page
  }

  page.drawText(
    `${employeeName} (${employeeUsername}), ` +
    (isMonthly 
      ? `${months[timeframe][language]} ${year}` 
      : `${PDF_WEEK[language]} ${timeframe}, ${year}`),
      {
        x: 50,
        y: yPosition,
        size: 16
      }
  )

  yPosition -= 18 // Move Y position down for the next text

  page.drawText(
    `${hours?.toFixed(2)} ${AMOUNT_OF_HOURS[language]}, ` +
    `${SALARY[language]} ${salary?.toFixed(2)}`,
    {
      x: 50,
      y: yPosition,
      size: 16
    }
  )

  yPosition -= 40 // Space before listing shifts

  shifts.forEach((shift) => {
    if (yPosition < 60) addNewPage()

    page.drawText(
      `${DATE[language]} ${shift?.startDay}, ` +
      `${START_TIME[language]}: ${shift?.startTime}, ` +
      `${HOURS[language]}: ${shift?.hoursWorked?.toFixed(2)}, ` +
      `${LUNCH[language]}: ${(shift?.lunchHours * 60)?.toFixed(0)}min`,
      { 
        x: 50,
        y: yPosition,
        size: 12
      }
    )

    yPosition -= 14
  })

  const pdfBytes = await pdfDoc.save()
  return pdfBytes
}

export const generateSummaryPDF = async (userSummaries, isMonthly, companyName, timeframe, year, language) => {
  const pdfDoc = await PDFDocument.create()
  let page = pdfDoc.addPage()
  const { height } = page.getSize()

  let yPosition = height - 100 // Starting Y position for text

  const addNewPage = () => {
    page = pdfDoc.addPage()
    yPosition = height - 50 // Reset Y position for the new page
  }

  const titleOptions = {
    x: 50,
    y: yPosition,
    size: 16
  }

  page.drawText(
    `${companyName}, ${isMonthly ? months[timeframe][language] : PDF_WEEK[language] + ' ' + timeframe} ${year}`,
    titleOptions
  )

  yPosition -= 40

  userSummaries.forEach((summary, index) => {
    if (yPosition < 74) addNewPage()

    page.drawText(
      `${summary?.name} (${summary?.username}), ` +
      `${summary?.totalHoursWorked?.toFixed(2)} ${AMOUNT_OF_HOURS[language]}, ${OF_WHICH[language]}`,
      { 
        x: 50,
        y: yPosition,
        size: 12
      }
    )

    yPosition -= 14

    page.drawText(
      `${OVERTIME_HOURS[language]} ${summary?.totalOverTimeHours?.toFixed(2)}, ` +
      `${NIGHT_SHIFT_HOURS[language]} ${summary?.totalNightShiftHours?.toFixed(2)}, ` +
      `${LUNCH_TIMES[language]} ${(summary?.totalLunchHours * 60)?.toFixed(2)} ` +
      `${SALARY[language]} ${(summary?.totalSalary)?.toFixed(2)}`,
      {
        x: 50,
        y: yPosition,
        size: 12
      }
    )

    yPosition -= 28
  })

  const pdfBytes = await pdfDoc.save()
  return pdfBytes
}

export const createEmployeeExcel = (shifts) => {
  const transformedShifts = shifts?.map((shift) => {
    const newShift = { ...shift }

    newShift.startMonth += 1
    newShift.endMonth += 1

    delete newShift.id
    delete newShift._id
    delete newShift.__v
    delete newShift.userId
    delete newShift.company
    return newShift
  })

  const worksheet = XLSX.utils.json_to_sheet(transformedShifts)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Shifts')

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

  const data = new Blob([excelBuffer], { type: 'application/octet-stream' })
  return data
}

export const generateSummaryExcel = (userSummaries) => {
  const worksheet = XLSX.utils.json_to_sheet(userSummaries)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Summaries')

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })

  const data = new Blob([excelBuffer], { type: 'application/octet-stream' })
  return data
}

export const generateYearOptions = () => {
  const currentYear = new Date().getFullYear()

  const years = []
  
  for (let yyyy = currentYear; yyyy >= 2001; yyyy--) {
    years.push({ value: yyyy, label: yyyy })
  }

  return years
}
