import { useSelector } from "react-redux"

import { jwtDecode } from "jwt-decode"

import { selectCurrentToken } from "../features/auth/auth-slices/authSlice"

const useAuth = () => {
  const token = useSelector(selectCurrentToken)

  if (token) {
    const decoded = jwtDecode(token)

    const {
      username,
      name,
      userId,
      email,
      active,
      timerRunning,
      timerBeganTimestamp,
      defaultStartTime,
      defaultEndTime,
      defaultLunchHours,
      defaultLunchPaid,
      defaultNormalHours,
      defaultNormalRate,
      defaultCurrency,
      defaultOvertimeMultiplierEligible,
      defaultOvertimeMultiplier,
      defaultNightShiftBeginsAt,
      defaultNightShiftEndsAt,
      defaultNightShiftMultiplierEligible,
      defaultNightShiftMultiplier,
      defaultNightShiftContinuous,
      defaultCompany,
      defaultDescription,
      uuid,
      isUser,
    } = decoded.UserInfo

    return {
      username,
      name,
      userId,
      email,
      active,
      timerRunning,
      timerBeganTimestamp,
      defaultStartTime,
      defaultEndTime,
      defaultLunchHours,
      defaultLunchPaid,
      defaultNormalHours,
      defaultNormalRate,
      defaultCurrency,
      defaultOvertimeMultiplierEligible,
      defaultOvertimeMultiplier,
      defaultNightShiftBeginsAt,
      defaultNightShiftEndsAt,
      defaultNightShiftMultiplierEligible,
      defaultNightShiftMultiplier,
      defaultNightShiftContinuous,
      defaultCompany,
      defaultDescription,
      isUser,
      uuid
    }
  }

  return { username: '' }
}

export default useAuth
