import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import Select from "react-select"

import Swal from "sweetalert2"

import useAuth from "../../../hooks/useAuth"

import { selectCurrentLanguage } from "../../language/languageSlice"

import { useDeleteUserMutation, useUpdateUserMutation } from "../user-slices/usersApiSlice"
import { useRefreshMutation, useSendLogoutMutation } from "../../auth/auth-slices/authApiSlice"

import {
  useDeleteCompanyMutation,
  useGetUserCompaniesQuery,
  useUpdateCompanyMutation
} from "../../companies/company-slices/companiesApiSlice"

import { InputField } from "../../../components/InputField"

import { currencyOptions } from "../../consts/consts"

import { DECIMAL_NUMBER_REGEX, NAME_REGEX, NUMBER_INPUT_REGEX, PASSWORD_REGEX } from "../../utils/regex"

import {
  ADD_COMPANY,
  ATTENTION,
  COMPANY,
  COMPANY_ADD_ID,
  COMPANY_NAME,
  CONFIRM_NEW_PASSWORD,
  CURRENCY,
  DEFAULT_SHIFT_SETTINGS,
  DELETE_ACCOUNT,
  END_TIME,
  HOURLY_RATE,
  LUNCH_HOURS,
  LUNCH_HOURS_PAID,
  NAME,
  NEW_PASSWORD,
  NIGHT_SHIFT_BEGINS_AT,
  NIGHT_SHIFT_CONTINUOUS,
  NIGHT_SHIFT_ENDS_AT,
  NIGHT_SHIFT_MULTIPLIER,
  NIGHT_SHIFT_MULTIPLIER_ELIGIBLE,
  NO,
  NORMAL_HOURS_BEFORE_OVERTIME,
  NOT_SPECIFIED,
  OVERTIME_MULTIPLIER,
  OVERTIME_MULTIPLIER_ELIGIBLE,
  REMOVE_COMPANY,
  START_TIME,
  SUCCESS,
  UPDATE_USER,
  UPDATE_UUID,
  USER_ADD_ID,
  USER_UPDATED_SUCCESSFULLY,
  YES,
} from "../../../assets/texts/texts"

import {
  ACCOUNT_DELETED_TEXT,
  COPIED_TO_CLIPBOARD,
  CONFIRM_REMOVE_COMPANY_TEXT,
  INVALID_NAME,
  INVALID_PASSWORD,
  CONFIRM_DELETE_USER_ACCOUNT_TEXT,
  CONFIRM_DELETE_COMPANY_ACCOUNT_TEXT

} from "../../../assets/texts/alertTexts"

import { alerts } from "../../utils/alerts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons"
import { translateError } from "../../utils/translateErrorCodes"

const UserInfo = () => {
  const auth = useAuth()
  const language = useSelector(selectCurrentLanguage)
  const navigate = useNavigate()

  const NOT_SPECIFIED_COMPANY_OPTION = { value: "", label: NOT_SPECIFIED[language] }

  const [name, setName] = useState(auth?.name)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [defaultStartTime, setDefaultStartTime] = useState(auth?.defaultStartTime)
  const [defaultEndTime, setDefaultEndTime] = useState(auth?.defaultEndTime)
  const [defaultLunchHours, setDefaultLunchHours] = useState(auth?.defaultLunchHours)
  const [defaultLunchPaid, setDefaultLunchPaid] = useState(auth?.defaultLunchPaid)
  const [defaultNormalHours, setDefaultNormalHours] = useState(auth?.defaultNormalHours)
  const [defaultNormalRate, setDefaultNormalRate] = useState(auth?.defaultNormalRate ? auth?.defaultNormalRate : 10)
  const [defaultCurrency, setDefaultCurrency] = useState(auth?.defaultCurrency ? auth?.defaultCurrency : "")
  const [defaultOvertimeMultiplier, setDefaultOvertimeMultiplier] = useState(
    auth?.defaultOvertimeMultiplier?.toString()?.length ? (auth?.defaultOvertimeMultiplier * 100) : 50)
  const [defaultOvertimeMultiplierEligible, setDefaultOvertimeMultiplierEligible] = useState(
    auth?.defaultOvertimeMultiplierEligible)
  const [defaultNightShiftMultiplierEligible, setDefaultNightShiftMultiplierEligible] = useState(
    auth?.defaultNightShiftMultiplierEligible)
  const [defaultNightShiftBeginsAt, setDefaultNightShiftBeginsAt] = useState(auth?.defaultNightShiftBeginsAt)
  const [defaultNightShiftEndsAt, setDefaultNightShiftEndsAt] = useState(auth?.defaultNightShiftEndsAt)
  const [defaultNightShiftMultiplier, setDefaultNightShiftMultiplier] = useState(
    auth?.defaultNightShiftMultiplier?.toString()?.length ? (auth?.defaultNightShiftMultiplier * 100) : 50)
  const [defaultNightShiftContinuous, setDefaultNightShiftContinuous] = useState(auth?.defaultNightShiftContinuous)
  const [defaultCompanyOption, setDefaultCompanyOption] = useState(NOT_SPECIFIED_COMPANY_OPTION)
  const [companyUuid, setCompanyUuid] = useState("")

  const validPassword = PASSWORD_REGEX.test(password) && password === confirmPassword
  const validName = NAME_REGEX.test(name)

  const [sendLogout, {
    isLoading: isLogoutLoading,
    isSuccess: isLogoutSuccess,
    isError: isLogoutError,
    error: logoutError
  }] = useSendLogoutMutation()

  const {
    data: companies,
    isLoading: isCompaniesLoading,
    isSuccess: isCompaniesSuccess,
    refetch: refetchCompanies
  } = useGetUserCompaniesQuery(auth?.userId, {
    pollingInterval: 600000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
  })

  const [updateUser, { isLoading, isSuccess, isError, error }] = useUpdateUserMutation()

  const [updateCompany, {
    isLoading: isCompanyLoading,
    isSuccess: isCompanySuccess,
    isError: isCompanyError,
    error: companyError
  }] = useUpdateCompanyMutation()

  const [deleteUser, {
    isLoading: isDeleteUserLoading,
    isSuccess: isDeleteUserSuccess,
    isError: isDeleteUserError,
    error: deleteUserError,
  }] = useDeleteUserMutation()

  const [deleteCompany, {
    isLoading: isDeleteCompanyLoading,
    isSuccess: isDeleteCompanySuccess,
    isError: isDeleteCompanyError,
    error: deleteCompanyError,
  }] = useDeleteCompanyMutation()

  const [refresh, { isLoading: isRefreshLoading }] = useRefreshMutation()

  const loading = isLoading || isRefreshLoading || isCompanyLoading || isCompaniesLoading ||
    isDeleteUserLoading || isDeleteCompanyLoading || isLogoutLoading

  useEffect(() => {
    if (defaultCompanyOption.value === "") {
      setDefaultCompanyOption(NOT_SPECIFIED_COMPANY_OPTION)
    }
  }, [language])

  useEffect(() => {
    if (isCompaniesSuccess && !loading) {
      const companiesEntities = companies.entities
  
      const userDefaultCompany = companiesEntities[auth?.defaultCompany]

      const userDefaultCompanyOption = { value: userDefaultCompany?.id, label: userDefaultCompany?.name }
      
      if (auth?.defaultCompany) {
        setDefaultCompanyOption(userDefaultCompanyOption)
      }
    }
  }, [isCompaniesSuccess, companies, auth?.defaultCompany, setDefaultCompanyOption])

  useEffect(() => {
    if ((isSuccess || isCompanySuccess) && !loading) {
      alerts.successAlert(USER_UPDATED_SUCCESSFULLY[language], SUCCESS[language])
    }
  }, [isSuccess, isCompanySuccess])

  useEffect(() => {
    if ((isDeleteUserSuccess || isDeleteCompanySuccess) && !loading) {
      sendLogout()
      alerts.successAlert(ACCOUNT_DELETED_TEXT[language], SUCCESS[language])
    }
  }, [isDeleteUserSuccess, isDeleteCompanySuccess])

  useEffect(() => {
    if (isLogoutSuccess && !loading) navigate('/')
  }, [isLogoutSuccess])

  useEffect(() => {
    if (isError) alerts.errorAlert(translateError({ language, code: error?.data?.code }))
    if (isCompanyError) alerts.errorAlert(translateError({ language, code: companyError?.data?.code }))
    if (isDeleteUserError) alerts.errorAlert(translateError({ language, code: deleteUserError?.data?.code }))
    if (isDeleteCompanyError) alerts.errorAlert(translateError({ language, code: deleteCompanyError?.data?.code }))
    if (isLogoutError) console.log(translateError({ language, code: logoutError?.data?.code }))
  }, [isError, isCompanyError, isDeleteUserError, isDeleteCompanyError, isLogoutError])

  if (loading) return

  if (isCompaniesSuccess || !auth?.isUser) {
    const companiesIds = companies?.ids
    const companiesEntities = companies?.entities

    const userCompaniesOptions = !companiesIds?.length ? null :
    companiesIds?.map((companyId) => {
      const company = companiesEntities[companyId]
      const companyOption = { value: company.id, label: company.name }
      return companyOption
    })

    const companiesOptions = userCompaniesOptions?.length 
      ? [NOT_SPECIFIED_COMPANY_OPTION, ...userCompaniesOptions]
      : [NOT_SPECIFIED_COMPANY_OPTION]

    return (
      <>
        <Row>
          <Col>
            <InputField 
              id="name"
              label={auth?.isUser === true ? NAME[language] : COMPANY_NAME[language]}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <InputField 
              id="password"
              label={NEW_PASSWORD[language]}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputField 
              id="confirm-password"
              label={CONFIRM_NEW_PASSWORD[language]}
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {auth?.isUser !== true ? null :
              <Card>
                <CardHeader>
                  <Row>
                    <Col>
                      <h3>{DEFAULT_SHIFT_SETTINGS[language]}</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="ml-0">
                    <InputField
                      id="default-start-time"
                      label={START_TIME[language]}
                      type="time"
                      aria-label="Time"
                      value={defaultStartTime}
                      onChange={(e) => setDefaultStartTime(e.target.value)}
                    />
                  </Row>
                  <Row className="ml-0">
                    <InputField 
                      id="default-start-time"
                      label={END_TIME[language]}
                      type="time"
                      aria-label="Time"
                      value={defaultEndTime}
                      onChange={(e) => setDefaultEndTime(e.target.value)}
                    />
                  </Row>
                  <label
                    onClick={() => setDefaultLunchPaid((prev) => !prev)}
                    className="form-control-label"
                    htmlFor="default-lunch-hours-paid"
                  >
                    <span className="mr-2">{LUNCH_HOURS_PAID[language]}</span>
                    <FontAwesomeIcon
                      name="default-lunch-hours-paid"
                      size="xl"
                      icon={defaultLunchPaid ? faToggleOn : faToggleOff}
                      color={defaultLunchPaid ? "rgb(23, 152, 207)" : "grey"}
                    />
                  </label>
                  <InputField 
                    id="default-lunch-hours"
                    label={LUNCH_HOURS[language]}
                    type="text"
                    value={defaultLunchHours}
                    onChange={(e) => {
                      const value = e.target.value
            
                      if (NUMBER_INPUT_REGEX.test(value)) {
                        if (value === "") setDefaultLunchHours(value)
                        else setDefaultLunchHours(parseInt(value))
                      }
                    }}
                  />
                  <InputField 
                    id="default-normal-hours"
                    label={NORMAL_HOURS_BEFORE_OVERTIME[language]}
                    type="text"
                    value={defaultNormalHours}
                    onChange={(e) => {
                      const value = e.target.value
                      if (DECIMAL_NUMBER_REGEX.test(value)) setDefaultNormalHours(value)
                    }}
                  />
                  <InputField 
                    id="default-normal-rate"
                    label={HOURLY_RATE[language]}
                    type="text"
                    value={defaultNormalRate}
                    onChange={(e) => {
                      const value = e.target.value
                      if (DECIMAL_NUMBER_REGEX.test(value)) setDefaultNormalRate(value)
                    }}
                  />
                  <label className="form-control-label" htmlFor="default-currency">
                    {CURRENCY[language]}
                  </label>
                  <Select
                    className="mb-4"
                    id="default-currency"
                    name="default-currency"
                    options={currencyOptions}
                    value={{ value: defaultCurrency, label: defaultCurrency }}
                    onChange={(option) => setDefaultCurrency(option.value)}
                  />
                  <label 
                    onClick={() => setDefaultOvertimeMultiplierEligible((prev) => !prev)}
                    className="form-control-label"
                    htmlFor="default-overtime-multiplier-eligible"
                  >
                    <span className="mr-2">{OVERTIME_MULTIPLIER_ELIGIBLE[language]}</span>
                    <FontAwesomeIcon
                      name="default-overtime-multiplier-eligible"
                      size="xl"
                      icon={defaultOvertimeMultiplierEligible ? faToggleOn : faToggleOff}
                      color={defaultOvertimeMultiplierEligible ? "rgb(23, 152, 207)" : "grey"}
                    />
                  </label>
                  <InputField 
                    id="default-overtime-multiplier"
                    label={OVERTIME_MULTIPLIER[language]}
                    type="text"
                    value={defaultOvertimeMultiplier}
                    onChange={(e) => {
                      const value = e.target.value
            
                      if (NUMBER_INPUT_REGEX.test(value)) {
                        if (value === "") setDefaultOvertimeMultiplier(value)
                        else setDefaultOvertimeMultiplier(parseInt(value))
                      }
                    }}
                  />
                  <Row>
                    <Col>
                      <label
                        onClick={() => setDefaultNightShiftMultiplierEligible((prev) => !prev)}
                        className="form-control-label mt-2"
                        htmlFor="default-night-shift-multiplier-eligible"
                      >
                        <span className="mr-2">{NIGHT_SHIFT_MULTIPLIER_ELIGIBLE[language]}</span>
                        <FontAwesomeIcon
                          name="default-night-shift-multiplier-eligible"
                          size="xl"
                          icon={defaultNightShiftMultiplierEligible ? faToggleOn : faToggleOff}
                          color={defaultNightShiftMultiplierEligible ? "rgb(23, 152, 207)" : "grey"}
                        />
                      </label>                
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label
                        onClick={() => setDefaultNightShiftContinuous((prev) => !prev)}
                        className="form-control-label mt-2"
                        htmlFor="default-night-shift-continuous"
                      >
                        <span className="mr-2">{NIGHT_SHIFT_CONTINUOUS[language]}</span>
                        <FontAwesomeIcon
                          name="default-night-shift-continuous"
                          id="default-night-shift-continuous"
                          size="xl"
                          icon={defaultNightShiftContinuous ? faToggleOn : faToggleOff}
                          color={defaultNightShiftContinuous ? "rgb(23, 152, 207)" : "grey"}
                        />
                      </label>                
                    </Col>
                  </Row>
                  <InputField 
                    id="default-night-shift-multiplier"
                    label={NIGHT_SHIFT_MULTIPLIER[language]}
                    type="text"
                    value={defaultNightShiftMultiplier}
                    onChange={(e) => {
                      const value = e.target.value
            
                      if (NUMBER_INPUT_REGEX.test(value)) {
                        if (value === "") setDefaultNightShiftMultiplier(value)
                        else setDefaultNightShiftMultiplier(parseInt(value))
                      }
                    }}
                  />
                  <Row className="ml-0">
                    <InputField 
                      id="default-night-shift-begins-at"
                      label={NIGHT_SHIFT_BEGINS_AT[language]}
                      type="time"
                      aria-label="Time"
                      value={defaultNightShiftBeginsAt}
                      onChange={(e) => setDefaultNightShiftBeginsAt(e.target.value)}
                    />
                  </Row>
                  <Row className="ml-0">
                    <InputField 
                      id="default-night-shift-ends-at"
                      label={NIGHT_SHIFT_ENDS_AT[language]}
                      type="time"
                      aria-label="Time"
                      value={defaultNightShiftEndsAt}
                      onChange={(e) => setDefaultNightShiftEndsAt(e.target.value)}
                    />
                  </Row>
                  <label className="form-control-label" htmlFor="default-company">
                    {COMPANY[language]}
                  </label>
                  <Select
                    className="mb-4"
                    id="default-company"
                    name="default-company"
                    options={companiesOptions}
                    value={defaultCompanyOption}
                    onChange={(option) => setDefaultCompanyOption(option)}
                  />
                  <Row>
                    <Col className="text-right">
                      <Button
                        style={{
                          backgroundColor: defaultCompanyOption?.value === "" ? "#f6f5f0" : '#fb6340',
                          color: 'white'
                        }}
                        disabled={defaultCompanyOption?.value === ""}
                        onClick={() => {
                          Swal.fire({
                            icon: "warning",
                            title: ATTENTION[language],
                            text: CONFIRM_REMOVE_COMPANY_TEXT[language] + defaultCompanyOption?.label,
                            showCancelButton: true,
                            confirmButtonText: YES[language],
                            cancelButtonText: NO[language],
                            reverseButtons: true,
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              if (defaultCompanyOption.value !== "" && auth?.isUser === true) {
                                await updateUser({
                                  id: auth?.userId,
                                  companyToRemove: defaultCompanyOption.value
                                })

                                setDefaultCompanyOption(NOT_SPECIFIED_COMPANY_OPTION)
                                refresh()
                                refetchCompanies()
                              }
                            }
                          })
                        }}
                      >
                        {REMOVE_COMPANY[language]}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            }
            <Row className="mt-5">
              <Col className="text-right">
                <Button
                  type="button"
                  style={{ backgroundColor: '#0099da', color: 'white' }}
                  onClick={async () => {
                    if (password?.length && !validPassword) {
                      alerts.errorAlert(INVALID_PASSWORD[language])
                    } else if (name?.length && !validName) {
                      alerts.errorAlert(INVALID_NAME[language])
                    } else if (auth?.isUser === true) {
                      await updateUser({
                        id: auth?.userId,
                        name: validName ? name : null,
                        password: validPassword ? password : null,
                        defaultStartTime,
                        defaultEndTime,
                        defaultLunchHours,
                        defaultLunchPaid,
                        defaultNormalHours: parseFloat(defaultNormalHours),
                        defaultNormalRate: parseFloat(defaultNormalRate),
                        defaultCurrency,
                        defaultOvertimeMultiplier: defaultOvertimeMultiplier?.toString()?.length
                          ? (defaultOvertimeMultiplier / 100)
                          : null,
                        defaultOvertimeMultiplierEligible,
                        defaultNightShiftMultiplierEligible,
                        defaultNightShiftBeginsAt,
                        defaultNightShiftEndsAt,
                        defaultNightShiftMultiplier: defaultNightShiftMultiplier?.toString()?.length
                          ? (defaultNightShiftMultiplier / 100)
                          : null,
                        defaultNightShiftContinuous,
                        defaultCompany: defaultCompanyOption?.value
                      })
                    } else if (auth?.isUser === false && typeof auth?.isUser === 'boolean') {
                      await updateCompany({
                        id: auth?.userId,
                        password: validPassword ? password : null,
                        name: validName ? name : null
                      })
                    }
                    setPassword("")
                    setConfirmPassword("")
                    refresh()
                  }}
                >
                  {UPDATE_USER[language]}
                </Button>
              </Col>
            </Row>
            {auth?.isUser === true ? null :
              <>
                <Row className="mt-4">
                  <Col>
                    {COMPANY_ADD_ID[language]}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col style={{ fontWeight: 'bold' }}>
                    {auth?.uuid}
                    <FontAwesomeIcon
                      className="ml-2"
                      size="xl"
                      icon={faCopy}
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigator.clipboard.writeText(`${auth?.uuid}`).then(() => {
                        Swal.fire({
                          toast: true,
                          position: 'top-end',
                          icon: 'success',
                          title: COPIED_TO_CLIPBOARD[language],
                          showConfirmButton: false,
                          timer: 1500,
                          timerProgressBar: true,
                        })
                      })}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="text-right">
                    <Button
                      type="button"
                      style={{ backgroundColor: '#fb6340', color: 'white' }}
                      onClick={async () => {
                        if (auth?.isUser === false && typeof auth?.isUser === 'boolean') {
                          await updateCompany({
                            id: auth?.userId,
                            newUuid: true
                          })

                          refresh()
                        }
                      }}
                    >
                      {UPDATE_UUID[language]}
                    </Button>
                  </Col>
                </Row>
              </>
            }
            {auth?.isUser !== true ? null :
              <>
                <Row className="mt-4">
                  <Col style={{ fontWeight: 'bold' }}>
                    <InputField
                      id="add-company-by-unique-id"
                      name="add-company-by-unique-id"
                      type="text"
                      label={USER_ADD_ID[language]}
                      value={companyUuid}
                      onChange={(e) => setCompanyUuid(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="text-right">
                    <Button
                      type="button"
                      style={{ backgroundColor: '#08c792', color: 'white' }}
                      onClick={async () => {
                        if (auth?.isUser === true) {
                          await updateUser({
                            id: auth?.userId,
                            companyUuid
                          })

                          setCompanyUuid("")
                          refresh()
                          refetchCompanies()
                        }
                      }}
                    >
                      {ADD_COMPANY[language]}
                    </Button>
                  </Col>
                </Row>
              </>
            }
            <hr
              className="mt-6"
            />
            <Row className="mt-6">
              <Col className="text-right">
                <Button
                  style={{ backgroundColor: '#ed2939', color: 'white' }}
                  onClick={async () => {
                    Swal.fire({
                      icon: "warning",
                      title: ATTENTION[language],
                      text: auth?.isUser === true 
                        ? CONFIRM_DELETE_USER_ACCOUNT_TEXT[language]
                        : CONFIRM_DELETE_COMPANY_ACCOUNT_TEXT[language],
                      showCancelButton: true,
                      confirmButtonText: YES[language],
                      cancelButtonText: NO[language],
                      reverseButtons: true,
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        if (auth?.isUser === true) {
                          await deleteUser({ id: auth?.userId })
                        } else {
                          await deleteCompany({ id: auth?.userId })
                        }
                      }
                    })
                  }}
                >
                  {DELETE_ACCOUNT[language]}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  return
}

export default UserInfo
