import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Select from "react-select"
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap"

import useAuth from "../../../hooks/useAuth"

import Shift from "./Shift"

import { selectCurrentLanguage } from "../../language/languageSlice"

import { useGetUserShiftsQuery } from "../shift-slices/userShiftsApiSlice"
import { useGetUserCompaniesQuery } from "../../companies/company-slices/companiesApiSlice"

import { InputField } from "../../../components/InputField"

import { months } from "../../consts/consts"

import { createEmployeeExcel, createEmployeeShiftsPDF, generateYearOptions, getWeekNumber } from "../../utils/utils"
import { NUMBER_INPUT_REGEX } from "../../utils/regex"

import {
  ALL_COMPANIES,
  AMOUNT_OF_HOURS,
  CLOSE,
  COMPANY,
  CURRENCY_EXPLANATION,
  DESCRIPTION_EXPLANATION,
  DOWNLOAD,
  END_DAY_EXPLANATION,
  END_MONTH_EXPLANATION,
  END_TIME_EXPLANATION,
  END_WEEK_EXPLANATION,
  END_YEAR_EXPLANATION,
  EXCEL_FIELDS_EXPLAINED,
  EXCEL_GUIDE,
  HOURS_WORKED_EXPLANATION,
  JUST_SHIFTS,
  LUNCH_HOURS_EXPLANATION,
  LUNCH_PAID_EXPLANATION,
  MONTH,
  NORMAL_HOURS_EXPLANATION,
  NORMAL_RATE_EXPLANATION,
  NS_BEGINS_AT_EXPLANATION,
  NS_CONTINUOUS_EXPLANATION,
  NS_ENDS_AT_EXPLANATION,
  NS_HOURS_EXPLANATION,
  NS_MULTIPLIER_ELIGIBLE_EXPLANATION,
  NS_MULTIPLIER_EXPLANATION,
  OT_HOURS_EXPLANATION,
  OT_MULTIPLIER_ELIGIBLE_EXPLANATION,
  OT_MULTIPLIER_EXPLANATION,
  PDF_WEEK,
  SHIFT,
  SHIFT_SALARY_EXPLANATION,
  SHIFTS,
  START_DAY_EXPLANATION,
  START_MONTH_EXPLANATION,
  START_TIME_EXPLANATION,
  START_WEEK_EXPLANATION,
  START_YEAR_EXPLANATION,
  WEEK,
  WEEKLY_SHIFTS,
  YEAR,
} from "../../../assets/texts/texts"

import { alerts } from "../../utils/alerts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons"
import { translateError } from "../../utils/translateErrorCodes"

const UserShifts = () => {
  const auth = useAuth();
  const language = useSelector(selectCurrentLanguage)
  const selectedCompanyUser = useSelector(state => state.companyUser.selectedCompanyUser)
  const allCompaniesOption = { value: "", label: ALL_COMPANIES[language] }

  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth())
  const [week, setWeek] = useState(getWeekNumber(new Date()))
  const [weeklyShifts, setWeeklyShifts] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [companyOption, setCompanyOption] = useState(allCompaniesOption)

  const queryId = auth?.isUser ? auth?.userId : selectedCompanyUser?.id

  const {
    data: companies,
    isLoading: isCompaniesLoading,
  } = useGetUserCompaniesQuery(auth?.userId, {
    pollingInterval: 600000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
  })

  const {
    data: shifts,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetUserShiftsQuery({
    periodType: weeklyShifts
      ? 'weekshifts'
      : 'monthshifts',
    queryId,
    companyId: auth?.userId,
    year: year,
    period: weeklyShifts ? week : month
  }, {
    pollingInterval: 600000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (companyOption.value === "") {
      setCompanyOption(allCompaniesOption)
    }
  }, [language])

  useEffect(() => {
    if (isError) {
      alerts.errorAlert(translateError({ language, code: error?.data?.code }))
      return
    }
  }, [isError])

  if (isLoading || isCompaniesLoading) return

  if (isSuccess && !isLoading && !isCompaniesLoading) {
    const companyIds = companies?.ids
    const companyEntities = companies?.entities
    const allCompanies = companyIds?.map((id) => companyEntities[id])

    const userCompanies = !auth?.isUser ? null : allCompanies?.map((company) => {
      return { value: company.id, label: company.name }
    })

    const allCompaniesOptions = !auth?.isUser ? null : [allCompaniesOption, ...userCompanies]

    const shiftIds = shifts?.ids
    const shiftEntities = shifts?.entities
    
    const shiftsList = shiftIds?.map((id) => shiftEntities[id])

    const filteredShifts = shiftsList?.filter((shift) => 
      shift.company === companyOption.value || companyOption.value === "").sort((a, b) => b.startDay - a.startDay)

    let periodHours = 0
    let periodSalary = 0

    filteredShifts.forEach((shift) => {
      periodHours += shift.hoursWorked
      periodSalary += shift.shiftSalary
    })

    const timeframe = weeklyShifts ? `${PDF_WEEK[language]}-${week}` : months[month][language]

    return (
      <>
        <Modal
          isOpen={modalOpened}
          toggle={() => {
            setModalOpened((prev) => !prev);
          }}
          keyboard={true}
          backdrop={true}
        >
          <div style={{ position: "relative", right: "2vw", top: "2vh" }}>
            <button
              style={{ cursor: "pointer", width: "40px", height: "40px" }}
              className="close"
              onClick={() => {
                setModalOpened((prev) => !prev)
              }}
              type="button"
            >
              &times;
            </button>
          </div>
          <ModalBody>
            <Row>
              <Col>
                <h3>{EXCEL_FIELDS_EXPLAINED[language]}</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <b>startYear:</b> {START_YEAR_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>startMonth:</b> {START_MONTH_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>startWeek:</b> {START_WEEK_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>startDay:</b> {START_DAY_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>startTime:</b> {START_TIME_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>endTime:</b> {END_TIME_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>endYear:</b> {END_YEAR_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>endMonth:</b> {END_MONTH_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>endWeek:</b> {END_WEEK_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>endDay:</b> {END_DAY_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>hoursWorked:</b> {HOURS_WORKED_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>lunchHours:</b> {LUNCH_HOURS_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>lunchPaid:</b> {LUNCH_PAID_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>normalHours:</b> {NORMAL_HOURS_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>normalRate:</b> {NORMAL_RATE_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>currency:</b> {CURRENCY_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>overTimeMultiplier:</b> {OT_MULTIPLIER_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>overTimeMultiplierEligible:</b> {OT_MULTIPLIER_ELIGIBLE_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>overTimeHours:</b> {OT_HOURS_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>nightShiftMultiplierEligible:</b> {NS_MULTIPLIER_ELIGIBLE_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>nightShiftContinuous:</b> {NS_CONTINUOUS_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>nightShiftBeginsAt:</b> {NS_BEGINS_AT_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>nightShiftEndsAt:</b> {NS_ENDS_AT_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>nightShiftMultiplier:</b> {NS_MULTIPLIER_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>nightShiftHours:</b> {NS_HOURS_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>shiftSalary:</b> {SHIFT_SALARY_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>description:</b> {DESCRIPTION_EXPLANATION[language]}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col className='text-right'>
                <Button
                  onClick={() => setModalOpened((prev) => !prev)}
                >
                  {CLOSE[language]}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Row>
          <Col>
            <h2>
              {auth?.isUser ? null : `${selectedCompanyUser?.name} `}
              {JUST_SHIFTS[language]}
            </h2>
          </Col>
          <Col className="text-right">
            <label onClick={() => setWeeklyShifts((prev) => !prev)} className="form-control-label" htmlFor="weekly-shifts">
              <span className="mr-2">{WEEKLY_SHIFTS[language]}</span>
              <FontAwesomeIcon
                name="weekly-shifts"
                size="xl"
                icon={weeklyShifts ? faToggleOn : faToggleOff}
                color={weeklyShifts ? "rgb(23, 152, 207)" : "grey"}
              />
            </label>
          </Col>
        </Row>
        {!auth?.isUser ? null :
          <Row>
            <Col>
              <label className="form-control-label" htmlFor="select-company">
                {COMPANY[language]}
              </label>
              <Select
                className="mb-4"
                id="select-company"
                name="select-company"
                options={allCompaniesOptions}
                value={companyOption}
                onChange={(option) => setCompanyOption(option)}
              />
            </Col>
          </Row>
        }
        <Row>
          <Col>
          {!weeklyShifts 
            ? <>
              <label className="form-control-label" htmlFor="select-month">
                {MONTH[language]}
              </label>
              <Select
                className="mb-4"
                id="select-month"
                name="select-month"
                options={[
                  { value: 0, label: months[0][language] },
                  { value: 1, label: months[1][language] },
                  { value: 2, label: months[2][language] },
                  { value: 3, label: months[3][language] },
                  { value: 4, label: months[4][language] },
                  { value: 5, label: months[5][language] },
                  { value: 6, label: months[6][language] },
                  { value: 7, label: months[7][language] },
                  { value: 8, label: months[8][language] },
                  { value: 9, label: months[9][language] },
                  { value: 10, label: months[10][language] },
                  { value: 11, label: months[11][language] }
                ]}
                  value={{ value: month, label: months[month][language] }}
                  onChange={(option) => setMonth(option.value)}
                />
              </>
            : <>
              <InputField
                id="week"
                name="week"
                style={{ height: "38px" }}
                label={WEEK[language]}
                type="text"
                value={week}
                onChange={(e) => {
                  const value = e.target.value
            
                  if (NUMBER_INPUT_REGEX.test(value)) {
                    if (value === "") setWeek(value)
                    else setWeek(parseInt(value))
                  }
                }}
              />
            </>
          }
          </Col>
          <Col>
            <label className="form-control-label" htmlFor="select-year">
              {YEAR[language]}
            </label>
            <Select
              className="mb-4"
              id="select-year"
              name="select-year"
              options={generateYearOptions()}
              value={{ value: year, label: year }}
              onChange={(option) => setYear(option.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              style={{ backgroundColor: '#0099da', color: 'white' }}
              type='button'
              onClick={() => setModalOpened((prev) => !prev)}
            >
              {EXCEL_GUIDE[language]}
            </Button>
          </Col>
          <Col className="text-right">
            <Button
              type="button"
              style={{ backgroundColor: '#08c792', color: 'white' }}
              onClick={async () => {
                const name = auth?.isUser === true ? auth?.name : selectedCompanyUser?.name

                const excelData = createEmployeeExcel(filteredShifts);
                const link = document.createElement('a');
                link.href = URL.createObjectURL(excelData);
                link.download = `${name?.split(' ').join('-')}-${timeframe}-${year}.xlsx`;
                link.click();
              }}
            >
              {DOWNLOAD[language]} XLSX
            </Button>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-right">
            <Button
              type="button"
              style={{ backgroundColor: '#08c792', color: 'white' }}
              onClick={async () => {
                const pdfBytes = await createEmployeeShiftsPDF(
                  filteredShifts,
                  auth?.isUser === true ? auth?.name : selectedCompanyUser?.name,
                  auth?.isUser === true ? auth?.username : selectedCompanyUser?.username,
                  !weeklyShifts,
                  weeklyShifts ? week : month,
                  year,
                  periodHours,
                  language,
                  periodSalary
                )

                const name = auth?.isUser === true ? auth?.name : selectedCompanyUser?.name

                const blob = new Blob([pdfBytes], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `${name?.split(' ').join('-')}-${timeframe}-${year}.pdf`;
                link.click();
              }}
            >
              {DOWNLOAD[language]} PDF
            </Button>
          </Col>
        </Row>
        <Row className="mt-3 mb-3">
          <Col>
            {filteredShifts?.length}
            {' '}
            {filteredShifts?.length === 1
              ? SHIFT[language] 
              : SHIFTS[language]
            },
            {' '}
            {periodHours?.toFixed(2)}
            {' '}
            {AMOUNT_OF_HOURS[language]},
            {' '}
            ${periodSalary?.toFixed(2)}
          </Col>
        </Row>
        {!filteredShifts?.length ? null :
          filteredShifts?.map((shift) => (
            <Shift key={shift?.id} isUser={auth?.isUser} shift={shift} language={language} refetchShifts={refetch} />
          ))
        }
      </>
    )
  }
}

export default UserShifts
