import { Language } from "./languages.ts";

export const ALL_FIELDS_REQUIRED = {
  [Language.English]: 'All fields are required',
  [Language.Estonian]: 'Kõik väljad on kohustuslikud',
  [Language.Norwegian]: 'Alle felt er obligatoriske',
};

export const UNAUTHORIZED = {
  [Language.English]: 'Unauthorized',
  [Language.Estonian]: 'Volitamata',
  [Language.Norwegian]: 'Uautorisert',
};

export const FORBIDDEN = {
  [Language.English]: 'Forbidden',
  [Language.Estonian]: 'Keelatud',
  [Language.Norwegian]: 'Forbudt',
};

export const UNDEFINED_ERROR = {
  [Language.English]: 'Undefined error',
  [Language.Estonian]: 'Määratlemata viga',
  [Language.Norwegian]: 'Udefinert feil',
};

export const COMPANY_ALREADY_IN_LIST = {
  [Language.English]: "Company is already added",
  [Language.Estonian]: 'Ettevõte on juba lisatud',
  [Language.Norwegian]: 'Selskapet er allerede lagt til',
};

export const COMPANY_NOT_IN_LIST = {
  [Language.English]: "Company is not in user's list of companies",
  [Language.Estonian]: 'Ettevõte ei ole kasutaja firmade nimekirjas',
  [Language.Norwegian]: 'Selskapet er ikke på brukerens liste over selskaper',
};

export const INVALID_DEFAULT_LUNCH_HOURS = {
  [Language.English]: 'Default lunch hours not within 0-120',
  [Language.Estonian]: 'Vaikimisi lõuna aeg ei ole vahemikus 0-120',
  [Language.Norwegian]: 'Standard lunsjtider ikke innenfor 0-120',
};

export const INVALID_CURRENCY = {
  [Language.English]: 'Invalid currency',
  [Language.Estonian]: 'Vale valuuta',
  [Language.Norwegian]: 'Ugyldig valuta',
};

export const INVALID_MULTIPLIER_PERCENTAGE = {
  [Language.English]: 'Multiplier percentage is not within 0-1000%',
  [Language.Estonian]: 'Kordaja protsent ei ole vahemikus 0-1000%',
  [Language.Norwegian]: 'Multiplikatorprosent er ikke innenfor 0-1000%',
};

export const INVALID_YEAR = {
  [Language.English]: 'Invalid year',
  [Language.Estonian]: 'Kehtetu aasta',
  [Language.Norwegian]: 'Ugyldig år',
};

export const INVALID_MONTH = {
  [Language.English]: 'Invalid month',
  [Language.Estonian]: 'Kehtetu kuu',
  [Language.Norwegian]: 'Ugyldig måned',
};

export const INVALID_DAY = {
  [Language.English]: 'Invalid day',
  [Language.Estonian]: 'Kehtetu päev',
  [Language.Norwegian]: 'Ugyldig dag',
};

export const INVALID_WEEK = {
  [Language.English]: 'Invalid week',
  [Language.Estonian]: 'Kehtetu nädal',
  [Language.Norwegian]: 'Ugyldig uke',
};

export const INVALID_TIME = {
  [Language.English]: 'Invalid time',
  [Language.Estonian]: 'Kehtetu kellaaeg',
  [Language.Norwegian]: 'Ugyldig tid',
};

export const DUPLICATE_NAME = {
  [Language.English]: 'Duplicate name',
  [Language.Estonian]: 'Nimi on juba võetud',
  [Language.Norwegian]: 'Duplikat navn',
};

export const DUPLICATE_USERNAME = {
  [Language.English]: 'Duplicate username',
  [Language.Estonian]: 'Kasutajanimi on juba kasutuses',
  [Language.Norwegian]: 'Duplikat brukernavn',
};

export const DUPLICATE_EMAIL = {
  [Language.English]: 'Duplicate email',
  [Language.Estonian]: 'Email on juba kasutuses',
  [Language.Norwegian]: 'Duplikat e-post',
};

export const OBJECT_NOT_FOUND = {
  [Language.English]: 'Object not found',
  [Language.Estonian]: 'Objekti ei leitud',
  [Language.Norwegian]: 'Objekt ikke funnet',
};

export const SERVER_ERROR = {
  [Language.English]: 'Server error',
  [Language.Estonian]: 'Serveri viga',
  [Language.Norwegian]: 'Serverfeil',
};

export const USER_ID_NOT_FOUND = {
  [Language.English]: 'User ID not found',
  [Language.Estonian]: 'Kasutaja ID-d ei leitud',
  [Language.Norwegian]: 'Bruker ID ikke funnet',
};

export const START_YEAR_UNDER = {
  [Language.English]: 'Start year is under 2001',
  [Language.Estonian]: 'Algusaasta on väiksem kui 2001',
  [Language.Norwegian]: 'Startåret er under 2001',
};

export const START_YEAR_IN_FUTURE = {
  [Language.English]: 'Start year is in the future',
  [Language.Estonian]: 'Algusaasta on tulevikus',
  [Language.Norwegian]: 'Startåret ligger i fremtiden',
};

export const INVALID_START_MONTH = {
  [Language.English]: 'Start month is not within 1-12',
  [Language.Estonian]: 'Alguskuu ei ole vahemikus 1-12',
  [Language.Norwegian]: 'Startmåneden er ikke innenfor 1-12',
};

export const INVALID_START_DAY = {
  [Language.English]: 'Start day is not within 1-31',
  [Language.Estonian]: 'Alguspäev ei ole vahemikus 1-31',
  [Language.Norwegian]: 'Startdagen er ikke innenfor 1-31',
};

export const INVALID_START_WEEK = {
  [Language.English]: 'Start week is not within 1-53',
  [Language.Estonian]: 'Algusnädal ei ole vahemikus 1-53',
  [Language.Norwegian]: 'Startuken er ikke innenfor 1-53',
};

export const INVALID_START_TIME = {
  [Language.English]: 'Invalid start time',
  [Language.Estonian]: 'Vigane algusaeg',
  [Language.Norwegian]: 'Ugyldig starttid',
};

export const INVALID_END_TIME = {
  [Language.English]: 'Invalid end time',
  [Language.Estonian]: 'Vigane lõppaeg',
  [Language.Norwegian]: 'Ugyldig sluttid',
};

export const END_YEAR_UNDER = {
  [Language.English]: 'End year is under 2001',
  [Language.Estonian]: 'Lõppaasta on väiksem kui 2001',
  [Language.Norwegian]: 'Sluttåret er under 2001',
};

export const END_YEAR_IN_FUTURE = {
  [Language.English]: 'End year is in the future',
  [Language.Estonian]: 'Lõppaasta on tulevikus',
  [Language.Norwegian]: 'Sluttåret ligger i fremtiden',
};

export const INVALID_END_MONTH = {
  [Language.English]: 'End month is not within 1-12',
  [Language.Estonian]: 'Lõppkuu ei ole vahemikus 1-12',
  [Language.Norwegian]: 'Sluttmåneden er ikke innenfor 1-12',
};

export const INVALID_END_DAY = {
  [Language.English]: 'End day is not within 1-31',
  [Language.Estonian]: 'Lõpppäev ei ole vahemikus 1-31',
  [Language.Norwegian]: 'Sluttdagen er ikke innenfor 1-31',
};

export const INVALID_END_WEEK = {
  [Language.English]: 'End week is not within 1-53',
  [Language.Estonian]: 'Lõppnädal ei ole vahemikus 1-53',
  [Language.Norwegian]: 'Sluttuken er ikke innenfor 1-53',
};

export const INVALID_WORK_HOURS = {
  [Language.English]: 'Worked hours is not within 0-120 hours',
  [Language.Estonian]: 'Töötunnid ei ole vahemikus 0-120',
  [Language.Norwegian]: 'Arbeidstid er ikke innenfor 0-120 timer',
};

export const INVALID_NORMAL_HOURS = {
  [Language.English]: 'Normal hours is not within 0-120 hours',
  [Language.Estonian]: 'Tavatunnid enne ületunde ei ole vahemikus 0-120',
  [Language.Norwegian]: 'Normale timer er ikke innenfor 0-120 timer',
};

export const INVALID_LUNCH_HOURS = {
  [Language.English]: 'Either invalid lunch time or end time is before start time',
  [Language.Estonian]: 'Vigane lõunaaeg või lõppaeg on enne algusaega',
  [Language.Norwegian]: 'Enten ugyldig lunsjtid eller sluttid er før starttid',
};

export const INVALID_HOURLY_RATE = {
  [Language.English]: 'Hourly rate is not within 0-1,000,000',
  [Language.Estonian]: 'Tunnitasu ei ole vahemikus 0-1,000,000',
  [Language.Norwegian]: 'Timeprisen er ikke innenfor 0-1,000,000',
};

export const DESCRIPTION_TOO_LONG = {
  [Language.English]: 'Description is too long (>255)',
  [Language.Estonian]: 'Kirjeldus on liiga pikk (>255)',
  [Language.Norwegian]: 'Beskrivelsen er for lang (>255)',
};

export const INVALID_OVERTIME_MULTIPLIER = {
  [Language.English]: 'Overtime multiplier is set to be eligible, but is not within 0-1000%',
  [Language.Estonian]: 'Ületunnitasu on aktiveeritud, kuid pole määratud vahemikku 0-1000%',
  [Language.Norwegian]: 'Overtidsmultiplikator er satt til å være kvalifisert, men er ikke innenfor 0-1000%',
};

export const INVALID_NIGHT_SHIFT_MULTIPLIER = {
  [Language.English]: 'Night shift multiplier is set to be eligible, but is not within 0-1000%',
  [Language.Estonian]: 'Öötöö tasu on aktiveeritud, kuid pole määratud vahemikku 0-1000%',
  [Language.Norwegian]: 'Nattskiftmultiplikator er satt til å være kvalifisert, men er ikke innenfor 0-1000%',
};

export const INVALID_NS_START_TIME = {
  [Language.English]: "Night shift multiplier is set to be eligible, but it's start time is invalid",
  [Language.Estonian]: 'Öötöö tasu on aktiveeritud, kuid selle algusaeg on vigane',
  [Language.Norwegian]: 'Nattskiftmultiplikator er satt til å være kvalifisert, men starttiden er ugyldig',
};

export const INVALID_NS_END_TIME = {
  [Language.English]: "Night shift is not set to be continuous, but it's end time is invalid",
  [Language.Estonian]: 'Öötöö ei ole kestvaks määratud ning selle lõppaeg on vigane',
  [Language.Norwegian]: 'Nattskift er ikke satt til å være kontinuerlig, men slutttiden er ugyldig',
};

export const COPIED_TO_CLIPBOARD = {
  [Language.English]: "Copied to clipboard!",
  [Language.Estonian]: 'Link kopeeritud!',
  [Language.Norwegian]: 'Kopiert til utklippstavlen!',
};

export const INVALID_PASSWORD = {
  [Language.English]: "Passwords must match, be 8-20 characters long and only have characters from the English alphabet, numbers 0-9 and the following symbols: !@#%",
  [Language.Estonian]: 'Paroolid peavad sobima, olema vahemikus 8-20 karakterit kasutades tähti vaid inglise tähestikust A-z, numbreid 0-9 ning järgnevaid sümboleid: !@#%',
  [Language.Norwegian]: 'Passord må samsvare, være 8-20 tegn lange og kun ha tegn fra det engelske alfabetet, tallene 0-9 og følgende symboler: !@#%',
};

export const INVALID_NAME = {
  [Language.English]: "Name must be 1-30 characters long and only have characters from the English, Norwegian and Estonian alphabets",
  [Language.Estonian]: 'Nimi peab olema vahemikus 1-30 karakterit ning sisaldama tähti ainult inglise, norra ning eesti keele tähestikust',
  [Language.Norwegian]: 'Navnet må være 1-30 tegn langt og kun ha tegn fra de engelske, norske og estiske alfabetene',
};

export const INVALID_USERNAME = {
  [Language.English]: "Username must be 6-20 characters long and only have characters from the English alphabet, no spaces",
  [Language.Estonian]: 'Kasutajanimi peab olema vahemikus 6-20 karakterit ning sisaldama tähti ainult inglise keele tähestikust, ilma tühikuteta',
  [Language.Norwegian]: 'Brukernavnet må være 6-20 tegn langt og kun ha tegn fra det engelske alfabetet, ingen mellomrom',
};

export const INVALID_EMAIL = {
  [Language.English]: "Invalid email",
  [Language.Estonian]: 'Vigane email',
  [Language.Norwegian]: 'Ugyldig e-post',
};

export const PASSWORDS_DONT_MATCH = {
  [Language.English]: "Passwords do not match",
  [Language.Estonian]: 'Paroolid ei klapi',
  [Language.Norwegian]: 'Passord stemmer ikke',
};

export const NOT_AGREED_TO_TERMS = {
  [Language.English]: "You are required to agree to our Privacy Policy and Terms & Conditions",
  [Language.Estonian]: 'Peate nõustuma meie privaatsuspoliitika ning tingimustega',
  [Language.Norwegian]: 'Du må godta våre retningslinjer for personvern og vilkår og betingelser',
};

export const ACCOUNT_DELETED_TEXT = {
  [Language.English]: "Account deleted successfully",
  [Language.Estonian]: 'Konto kustutatud',
  [Language.Norwegian]: 'Kontoen ble slettet',
};

export const COMPLETE_CAPTCHA = {
  [Language.English]: 'Please complete the captcha',
  [Language.Estonian]: 'Palun täitke captcha',
  [Language.Norwegian]: 'Vennligst fullfør captchaen',
};

export const FINISH_SHIFT_WARNING = {
  [Language.English]: 'Please do not forget to fill out the rest of the form and save the shift. The dates and times are prefilled from the timer. If you have set standard shift settings on your profile, then those are prefilled as well.',
  [Language.Estonian]: 'Palun ärge unustage täita ülejäänud vorm ning salvestada vahetus. Kuupäevad ning kellaajad on eeltäidetud vastavalt taimerile. Kui olete profiilil salvestanud standard vahetuse sätted, on ka need väljad vastavalt eeltäidetud',
  [Language.Norwegian]: 'Ikke glem å fylle ut resten av skjemaet og lagre skiftet. Datoene og tidspunktene er forhåndsutfylt fra tidtakeren. Hvis du har angitt standard skiftinnstillinger på profilen din, er disse også forhåndsutfylt.',
}

export const CONFIRM_DELETE_SHIFT_TEXT = {
  [Language.English]: 'Are you sure you wish to delete the shift that started on day ',
  [Language.Estonian]: 'Kas olete kindel, et soovite kustutada vahetuse, mis algas kuupäeval ',
  [Language.Norwegian]: 'Er du sikker på at du ønsker å slette skiftet som startet på dag ',
}

export const CONFIRM_DELETE_USER_ACCOUNT_TEXT = {
  [Language.English]: 'Are you sure you wish to delete your account? All your shifts will be deleted along with it and will no longer be available to any of your employers.',
  [Language.Estonian]: 'Kas olete kindel, et soovite konto kustutada? Kõik teie vahetused kustutatakse koos kontoga. See tähendab, et need pole enam saadaval ka ühegi teie tööandja jaoks.',
  [Language.Norwegian]: 'Er du sikker på at du vil slette kontoen din? Alle skiftene dine vil bli slettet sammen med den og vil ikke lenger være tilgjengelig for noen av dine arbeidsgivere.',
}

export const CONFIRM_DELETE_COMPANY_ACCOUNT_TEXT = {
  [Language.English]: "Are you sure you wish to delete your account? The company will also be deleted from all of your employees' lists of employers",
  [Language.Estonian]: 'Kas olete kindel, et soovite konto kustutada? Firma eemaldatakse ka kõikide Teie töötajate tööandjate nimekirjast.',
  [Language.Norwegian]: 'Er du sikker på at du vil slette kontoen din? Selskapet vil også bli slettet fra alle dine ansattes lister over arbeidsgivere',
}

export const CONFIRM_REMOVE_COMPANY_TEXT = {
  [Language.English]: 'Are you sure you wish to remove this company from your list of companies? This company will then no longer be able to see the shifts you have logged under their name: ',
  [Language.Estonian]: 'Kas olete kindel, et soovite firma eemaldada oma tööandjate nimekirjast? Peale kinnitamist ei pääse antud tööandja enam ligi vahetustele, mis olete tema nimele kandnud: ',
  [Language.Norwegian]: 'Er du sikker på at du vil fjerne dette selskapet fra listen over selskaper? Dette selskapet vil da ikke lenger kunne se skiftene du har logget under deres navn: ',
}

export const CONFIRM_DELETE_USER_TEXT = {
  [Language.English]: 'Are you sure you wish to remove the rights to log shifts for your company from the following employee?: ',
  [Language.Estonian]: 'Kas olete kindel, et soovite eemaldada õiguse lisada vahetusi enda firmale järgneval töötajal?: ',
  [Language.Norwegian]: 'Er du sikker på at du vil fjerne rettighetene til å logge skift for din bedrift fra følgende arbeider?: ',
}

export const DELETE_USER_WARNING_TEXT = {
  [Language.English]: `Keep in mind that you will not be able to access this user's shifts after doing so. If you need to download their shifts first, please do so before confirming this action.`,
  [Language.Estonian]: 'Pidage meeles, et peale kinnitamist pole ka Teil enam ligipääsu antud kasutaja vahetustele. Kui teil on vaja tema vahetused alla laadida, siis tehke seda enne õiguste eemaldamise kinnitamist.',
  [Language.Norwegian]: 'Husk at du ikke vil ha tilgang til denne brukerens skift etter å ha gjort det. Hvis du trenger å laste ned deres skift først, vennligst gjør det før du bekrefter denne handlingen.',
}
