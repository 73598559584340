import { Col, Row } from "reactstrap"

const NoPage = () => {
  return (
    <Row>
      <Col>
        Error 404: Page not found.
      </Col>
    </Row>
  )
}

export default NoPage
