import Notification from './Notification'

import { useGetNotificationsQuery } from './notification-slices/notificationsApiSlice'

const NotificationsList = () => {
  const {
    data: notifications,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetNotificationsQuery("notificationsList", {
    pollingInterval: 600000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
  })

  if (isLoading) return
  if (isError) console.log(`${error?.data?.message}`)

  if (isSuccess) {
    const notificationIds = notifications?.ids
    const notificationEntities = notifications?.entities

    const notificationObjects = notificationIds?.map((id) => {
      return notificationEntities[id]
    })

    return (
      <>
        {notificationObjects?.map(notification => <Notification key={notification.id} notification={notification} />)} 
      </>
    )
  }

  return
}

export default NotificationsList
