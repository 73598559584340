import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"

import { apiSlice } from "../../../app/api/apiSlice"

const userShiftsAdapter = createEntityAdapter({})

const initialState = userShiftsAdapter.getInitialState()

export const userShiftsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserShifts: builder.query({
            query: (params) => ({
                url: `/shifts/${params.periodType}/${params.queryId}/${params.companyId}/${params.year}/${params.period}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedUserShifts = responseData.map(shift => {
                    shift.id = shift._id
                    return shift
                })
                return userShiftsAdapter.setAll(initialState, loadedUserShifts)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'UserShift', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'UserShift', id }))
                    ]
                } else return [{ type: 'UserShift', id: 'LIST' }]
            }
        }),
    }),
})

export const {
    useGetUserShiftsQuery,
} = userShiftsApiSlice

export const selectUserShiftsResult = userShiftsApiSlice.endpoints.getUserShifts.select()

const selectUserShiftsData = createSelector(
    selectUserShiftsResult,
    userShiftsResult => userShiftsResult.data
)

export const {
    selectAll: selectAllUserShifts,
    selectById: selectUserShiftById,
    selectIds: selectUserShiftIds
} = userShiftsAdapter.getSelectors(state => selectUserShiftsData(state) ?? initialState)
