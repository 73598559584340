import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"
import ReCAPTCHA from 'react-google-recaptcha';

import useAuth from "../../../hooks/useAuth"

import { selectCurrentLanguage } from "../../language/languageSlice"

import { useAddNewUserMutation } from "../user-slices/usersApiSlice"
import { useAddNewCompanyMutation } from "../../companies/company-slices/companiesApiSlice"

import { InputField } from "../../../components/InputField"

import { EMAIL_REGEX, NAME_REGEX, PASSWORD_REGEX, USERNAME_REGEX } from "../../utils/regex"

import {
  ACCOUNT_CREATED,
  ALREADY_HAVE_AN_ACCOUNT,
  COMPANY_NAME,
  CONFIRM_PASSWORD,
  LOGIN,
  NAME,
  PASSWORD,
  REGISTER,
  REGISTER_COMPANY_ACCOUNT,
  REGISTER_NEW_ACCOUNT,
  SUCCESS,
  USERNAME,
  PRIVACY_POLICY_AND_TC,
  I_AGREE
} from "../../../assets/texts/texts"

import {
  COMPLETE_CAPTCHA,
  INVALID_EMAIL,
  INVALID_NAME,
  INVALID_PASSWORD,
  INVALID_USERNAME,
  NOT_AGREED_TO_TERMS,
  PASSWORDS_DONT_MATCH
} from "../../../assets/texts/alertTexts"

import { alerts } from "../../utils/alerts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons"
import { translateError } from "../../utils/translateErrorCodes"

const NewUserForm = () => {
  const navigate = useNavigate()
  const auth = useAuth()

  const language = useSelector(selectCurrentLanguage)

  const [captchaToken, setCaptchaToken] = useState(null)
  const [username, setUsername] = useState("")
  const [validUsername, setValidUsername] = useState(false)
  const [password, setPassword] = useState("")
  const [validPassword, setValidPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [name, setName] = useState("")
  const [validName, setValidName] = useState(false)
  const [email, setEmail] = useState("")
  const [validEmail, setValidEmail] = useState(false)
  const [companyAccount, setCompanyAccount] = useState(false)
  const [agreedToTerms, setAgreedToTerms] = useState(false)

  const [addNewUser, { isLoading, isSuccess, isError, error }] =
    useAddNewUserMutation()

  const [addNewCompany, {
    isLoading: isCompanyLoading,
    isSuccess: isCompanySuccess,
    isError: isCompanyError,
    error: companyError
  }] = useAddNewCompanyMutation()

  const loading = isLoading || isCompanyLoading

  useEffect(() => {
  }, [language])

  useEffect(() => {
    setValidUsername(USERNAME_REGEX.test(username))
  }, [username])

  useEffect(() => {
    setValidPassword(PASSWORD_REGEX.test(password))
  }, [password])

  useEffect(() => {
    setValidName(NAME_REGEX.test(name))
  }, [name])

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email))
  }, [email])

  useEffect(() => {
    if (isSuccess || isCompanySuccess) {
      setUsername("")
      setPassword("")
      setConfirmPassword("")
      setName("")
      setEmail("")
      alerts.successAlert(`${ACCOUNT_CREATED[language]}`, `${SUCCESS[language]}`)
      navigate("/")
    }
  }, [isSuccess, isCompanySuccess, navigate])

  useEffect(() => {
      if (isError) alerts.errorAlert(translateError({ language, code: error?.data?.code }))
      if (isCompanyError) alerts.errorAlert(translateError({ language, code: companyError?.data?.code }))
  }, [isError, isCompanyError])

  const canSave = [validUsername, validPassword, validName, validEmail].every(Boolean) &&
    password === confirmPassword && agreedToTerms && !loading && captchaToken

  const handleCaptcha = (value) => {
    setCaptchaToken(value);
  }

  const handleSaveUserClicked = async () => {
    if (canSave) {
      if (companyAccount) {
        await addNewCompany({
          username: username?.toLowerCase(),
          password,
          name,
          email
        })
      } else {
        await addNewUser({
          username: username?.toLowerCase(),
          password,
          name,
          email
        })
      }

      setCaptchaToken(null)
    } else {
      if (!validUsername) alerts.errorAlert(INVALID_USERNAME[language])
      else if (!validEmail) alerts.errorAlert(INVALID_EMAIL[language])
      else if (!validName) alerts.errorAlert(INVALID_NAME[language])
      else if (!validPassword) alerts.errorAlert(INVALID_PASSWORD[language])
      else if (password !== confirmPassword) alerts.errorAlert(PASSWORDS_DONT_MATCH[language])
      else if (!agreedToTerms) alerts.errorAlert(NOT_AGREED_TO_TERMS[language])
      else if (!captchaToken) alerts.errorAlert(COMPLETE_CAPTCHA[language])

      setCaptchaToken(null)
    }
  }

  if (auth?.username?.length) {
    return (
      <p>
        You are currently logged in. Please log out before registering a new
        user.
      </p>
    )
  }

  if (loading) return

  return (
    <>
      <h2>
        {REGISTER_NEW_ACCOUNT[language]}
      </h2>
      <Row>
        <Col>
          <label
            onClick={() => setCompanyAccount((prev) => !prev)}
            className="form-control-label mt-2"
            htmlFor="company-account"
          >
            <span className="mr-2">{REGISTER_COMPANY_ACCOUNT[language]}</span>
            <FontAwesomeIcon
              name="company-account"
              id="company-account"
              size="xl"
              icon={companyAccount ? faToggleOn : faToggleOff}
              color={companyAccount ? "rgb(23, 152, 207)" : "grey"}
            />
          </label>        
        </Col>
      </Row>
      <InputField
        id="username"
        label={USERNAME[language]}
        type="text"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <InputField
        id="email"
        label="Email"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <InputField
        id="name"
        label={companyAccount ? COMPANY_NAME[language] : NAME[language]}
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <InputField 
        id="password"
        label={PASSWORD[language]}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <InputField 
        id="confirm-password"
        label={CONFIRM_PASSWORD[language]}
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <Row className="mt-5">
        <Col>
          <u
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/policy')}
          >
            {PRIVACY_POLICY_AND_TC[language]}
          </u>
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            className="form-control-label mt-2"
            htmlFor="agreed-to-terms"
          >
            <span className="mr-2">{I_AGREE[language]}</span>
            <FontAwesomeIcon
              onClick={() => setAgreedToTerms((prev) => !prev)}
              name="agreed-to-terms"
              id="agreed-to-terms"
              size="xl"
              icon={agreedToTerms ? faToggleOn : faToggleOff}
              color={agreedToTerms ? "rgb(23, 152, 207)" : "grey"}
            />
          </label>        
        </Col>
      </Row>
      <Row>
        <Col>
          <ReCAPTCHA
            sitekey="6LcPozwqAAAAAEzXty9ZMwzUfj1hf2S1-OT4TCI9"
            onChange={handleCaptcha}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            onClick={() => handleSaveUserClicked()}
            className="mr-4 mt-3"
            style={{ backgroundColor: '#08c792', color: 'white' }}
            type="button"
          >
            {REGISTER[language]}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col>
        {ALREADY_HAVE_AN_ACCOUNT[language]}
        {' '}
        <u style={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          {LOGIN[language]}
        </u>
        </Col>
      </Row>
    </>
  )
}

export default NewUserForm
