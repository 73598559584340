import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../../../app/api/apiSlice"

const shiftsAdapter = createEntityAdapter({})

const initialState = shiftsAdapter.getInitialState()

export const shiftsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addNewShift: builder.mutation({
            query: initialShiftData => ({
                url: '/shifts',
                method: 'POST',
                body: {
                    ...initialShiftData,
                }
            }),
            invalidatesTags: [
                { type: 'Shift', id: "LIST" }
            ]
        }),
        deleteShift: builder.mutation({
            query: initialShiftData => ({
                url: '/shifts',
                method: 'DELETE',
                body: { ...initialShiftData }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Shift', id: arg.id }
            ]
        }),
    }),
})

export const {
    useAddNewShiftMutation,
    useDeleteShiftMutation,
} = shiftsApiSlice
