import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import Select from "react-select"
import Calendar from "react-calendar"
import { Button, Card, CardBody, CardHeader, Col, Input, Row } from "reactstrap"

import useAuth from "../../../hooks/useAuth"

import { selectCurrentLanguage } from "../../language/languageSlice"

import { useGetUserCompaniesQuery } from "../../companies/company-slices/companiesApiSlice"
import { useAddNewShiftMutation } from "../shift-slices/shiftsApiSlice"

import { InputField } from "../../../components/InputField"

import { calculateHoursWorked, calculateNightShiftHours, getWeekNumber } from "../../utils/utils"
import { DECIMAL_NUMBER_REGEX, NUMBER_INPUT_REGEX, timeRegex } from "../../utils/regex"

import { currencyOptions } from "../../consts/consts"

import "../../../assets/css/customCalendar.css"

import {
  ADD_SHIFT,
  CURRENCY,
  HOURLY_RATE,
  END_DATE,
  END_TIME,
  LUNCH_HOURS,
  LUNCH_HOURS_PAID,
  NIGHT_SHIFT_BEGINS_AT,
  NIGHT_SHIFT_ENDS_AT,
  NIGHT_SHIFT_MULTIPLIER,
  NIGHT_SHIFT_MULTIPLIER_ELIGIBLE,
  OVERTIME_MULTIPLIER,
  OVERTIME_MULTIPLIER_ELIGIBLE,
  START_DATE,
  START_TIME,
  NORMAL_HOURS_BEFORE_OVERTIME,
  DAILY_AMOUNT_EARNED,
  NIGHT_SHIFT_CONTINUOUS,
  LUNCH_DEDUCTION_NB,
  DESCRIPTION,
  OPTIONAL,
  SHIFT_ADDED_SUCCESSFULLY,
  SUCCESS,
  COMPANY,
  NOT_SPECIFIED
} from "../../../assets/texts/texts"

import {
  DESCRIPTION_TOO_LONG,
  INVALID_END_TIME,
  INVALID_HOURLY_RATE,
  INVALID_LUNCH_HOURS,
  INVALID_NIGHT_SHIFT_MULTIPLIER,
  INVALID_NORMAL_HOURS,
  INVALID_NS_END_TIME,
  INVALID_NS_START_TIME,
  INVALID_OVERTIME_MULTIPLIER,
  INVALID_START_TIME,
  INVALID_WORK_HOURS,
  USER_ID_NOT_FOUND
} from "../../../assets/texts/alertTexts"

import { alerts } from "../../utils/alerts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons"
import { translateError } from "../../utils/translateErrorCodes"

const NewShiftForm = () => {
  const location = useLocation()
  const auth = useAuth()
  const language = useSelector(selectCurrentLanguage)

  const NOT_SPECIFIED_COMPANY_OPTION = { value: "", label: NOT_SPECIFIED[language] }

  const [startDate, setStartDate] = useState(new Date())
  const [startTime, setStartTime] = useState(auth?.defaultStartTime ? auth.defaultStartTime : "08:00")
  const [endDate, setEndDate] = useState(new Date())
  const [endTime, setEndTime] = useState(auth?.defaultEndTime ? auth.defaultEndTime : "16:00")
  const [normalHours, setNormalHours] = useState(auth?.defaultNormalHours?.toString()?.length ? auth.defaultNormalHours : 8)
  const [lunchHours, setLunchHours] = useState(
    auth?.defaultLunchHours || auth?.defaultLunchHours === 0 ? auth.defaultLunchHours : 60)
  const [lunchHoursPaid, setLunchHoursPaid] = useState(auth?.defaultLunchPaid ? auth.defaultLunchPaid : false)
  const [hourlyRate, setHourlyRate] = useState(auth?.defaultNormalRate?.toString()?.length ? auth.defaultNormalRate : 10.0)
  const [currency, setCurrency] = useState(auth?.defaultCurrency ? auth.defaultCurrency : "EUR")
  const [overTimeMultiplierEligible, setOverTimeMultiplierEligible] = useState(
    auth?.defaultOvertimeMultiplierEligible ? auth.defaultOvertimeMultiplierEligible : false)
  const [overTimeMultiplier, setOverTimeMultiplier] = useState(
    auth?.defaultOvertimeMultiplier?.toString()?.length ? (auth.defaultOvertimeMultiplier * 100) : 50)
  const [nightShiftMultiplierEligible, setNightShiftMultiplierEligible] = useState(
    auth?.defaultNightShiftMultiplierEligible ? auth.defaultNightShiftMultiplierEligible : false)
  const [nightShiftMultiplier, setNightShiftMultiplier] = useState(
    auth?.defaultNightShiftMultiplier?.toString()?.length ? (auth.defaultNightShiftMultiplier * 100) : 50)
  const [nightShiftBeginsAt, setNightShiftBeginsAt] = useState(
    auth?.defaultNightShiftBeginsAt ? auth.defaultNightShiftBeginsAt : "22:00")
  const [nightShiftEndsAt, setNightShiftEndsAt] = useState
  (auth?.defaultNightShiftEndsAt ? auth.defaultNightShiftEndsAt : "06:00")
  const [nightShiftContinuous, setNightShiftContinuous] = useState(
    auth?.defaultNightShiftContinuous ? auth.defaultNightShiftContinuous : false)
  const [description, setDescription] = useState("")
  const [companyOption, setCompanyOption] = useState(NOT_SPECIFIED_COMPANY_OPTION)

  const [addNewShift, { isLoading, isSuccess, isError, error }] =
    useAddNewShiftMutation()
  
  const {
    data: companies,
    isLoading: isCompaniesLoading,
    isSuccess: isCompaniesSuccess,
  } = useGetUserCompaniesQuery(auth?.userId, {
    pollingInterval: 600000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (location.state) {
      const { timerStartDate, timerEndDate } = location.state
      if (timerStartDate && timerEndDate) {
        setStartDate(timerStartDate)
        setEndDate(timerEndDate)
        setStartTime(timerStartDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false }))
        setEndTime(timerEndDate.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false }))
      }
    }
  }, [location.state])

  useEffect(() => {
    if (companyOption.value === "") {
      setCompanyOption(NOT_SPECIFIED_COMPANY_OPTION)
    }
  }, [language])

  useEffect(() => {
    if (isCompaniesSuccess && !isLoading) {
      const companiesEntities = companies.entities
  
      const userDefaultCompany = companiesEntities[auth?.defaultCompany]

      const userDefaultCompanyOption = { value: userDefaultCompany?.id, label: userDefaultCompany?.name }

      if (auth?.defaultCompany) {
        setCompanyOption(userDefaultCompanyOption)
      }
    }
  }, [isCompaniesSuccess, isLoading, companies, auth?.defaultCompany, setCompanyOption])

  useEffect(() => {
    if (isSuccess) {
      setStartDate(new Date())
      setStartTime(auth?.defaultStartTime ? auth.defaultStartTime : "08:00")
      setEndDate(new Date())
      setEndTime(auth?.defaultEndTime ? auth.defaultEndTime : "16:00")
      setNormalHours(auth?.defaultNormalHours?.toString()?.length ? auth.defaultNormalHours : 8)
      setLunchHours(auth?.defaultLunchHours || auth?.defaultLunchHours === 0 ? auth.defaultLunchHours : 60)
      setLunchHoursPaid(auth?.defaultLunchPaid ? auth.defaultLunchPaid : false)
      setHourlyRate(auth?.defaultNormalRate?.toString()?.length ? auth.defaultNormalRate : 10.0)
      setCurrency(auth?.defaultCurrency ? auth.defaultCurrency : "EUR")
      setOverTimeMultiplierEligible(auth?.defaultOvertimeMultiplierEligible ? auth.defaultOvertimeMultiplierEligible : false)
      setOverTimeMultiplier(auth?.defaultOvertimeMultiplier?.toString()?.length ? (auth.defaultOvertimeMultiplier * 100) : 50)
      setNightShiftMultiplierEligible(auth?.defaultNightShiftMultiplierEligible ? auth.defaultNightShiftMultiplierEligible : false)
      setNightShiftMultiplier(auth?.defaultNightShiftMultiplier?.toString()?.length ? (auth.defaultNightShiftMultiplier * 100) : 50)
      setNightShiftBeginsAt(auth?.defaultNightShiftBeginsAt ? auth.defaultNightShiftBeginsAt : "22:00")
      setNightShiftEndsAt(auth?.defaultNightShiftEndsAt ? auth.defaultNightShiftEndsAt : "06:00")
      setNightShiftContinuous(auth?.defaultNightShiftContinuous ? auth.defaultNightShiftContinuous : false)
      setDescription("")
      alerts.successAlert(`${SHIFT_ADDED_SUCCESSFULLY[language]}`, `${SUCCESS[language]}`)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) alerts.errorAlert(translateError({ language, code: error?.data?.code }))
  }, [isError])

  const workedHours = calculateHoursWorked(startDate, startTime, endDate, endTime)
  const overtimeHours = !overTimeMultiplierEligible
    ? 0
    : workedHours - normalHours < 0
      ? 0
      : workedHours - normalHours

  const nightShiftHours = !nightShiftMultiplierEligible 
    ? 0 
    : calculateNightShiftHours(startDate, startTime, nightShiftBeginsAt, nightShiftEndsAt, workedHours, nightShiftContinuous) < 0
      ? 0
      : calculateNightShiftHours(startDate, startTime, nightShiftBeginsAt, nightShiftEndsAt, workedHours, nightShiftContinuous)

  const lunchDeduction = lunchHoursPaid ? 0 : lunchHours / 60 * hourlyRate

  const shiftSalary = (
    workedHours * hourlyRate + 
    overTimeMultiplier / 100 * hourlyRate * overtimeHours +
    nightShiftMultiplier / 100 * hourlyRate * nightShiftHours -
    lunchDeduction
  ).toFixed(2)

  const handleSaveShiftClicked = async () => {
    const canSave = workedHours >= 0 && workedHours <= 120 && lunchHours >= 0 &&
      (lunchHours / 60 <= workedHours || lunchHoursPaid === true) && hourlyRate >= 0 && hourlyRate <= 1000000 &&
      currency.length === 3 && overTimeMultiplier >= 0 && overTimeMultiplier / 100 <= 10 && nightShiftMultiplier >= 0 &&
      nightShiftMultiplier / 100 <= 10 && auth?.userId?.length > 0 && timeRegex.test(startTime) === true &&
      timeRegex.test(endTime) === true && description.length <= 255 && normalHours >= 0 && normalHours <= 120
      && hourlyRate?.toString()?.length && lunchHours?.toString()?.length &&
      (overTimeMultiplier?.toString()?.length || !overTimeMultiplierEligible) &&
      (normalHours?.toString()?.length || !overTimeMultiplierEligible) &&
      (nightShiftMultiplier?.toString()?.length || !nightShiftMultiplierEligible)

    if (!auth?.userId?.length) alerts.errorAlert(USER_ID_NOT_FOUND[language])
    if (!timeRegex.test(startTime)) alerts.errorAlert(INVALID_START_TIME[language])
    if (!timeRegex.test(endTime)) alerts.errorAlert(INVALID_END_TIME[language])
    if (workedHours < 0 || workedHours > 120) alerts.errorAlert(INVALID_WORK_HOURS[language])

    if ((!lunchHours?.toString()?.length || lunchHours < 0 || lunchHours / 60 > workedHours) && !lunchHoursPaid) {
      alerts.errorAlert(INVALID_LUNCH_HOURS[language])
    }

    if (!hourlyRate?.toString()?.length || hourlyRate < 0 || hourlyRate > 1000000) alerts.errorAlert(INVALID_HOURLY_RATE[language])
    if (description.length > 255) alerts.errorAlert(DESCRIPTION_TOO_LONG[language])

    if (overTimeMultiplierEligible) {
      if (!overTimeMultiplier?.toString()?.length || overTimeMultiplier < 0 || overTimeMultiplier / 100 > 10) {
        alerts.errorAlert(INVALID_OVERTIME_MULTIPLIER[language])
      }
      
      if (!normalHours?.toString()?.length || normalHours < 0 || normalHours > 120) alerts.errorAlert(
        INVALID_NORMAL_HOURS[language])
    }

    if (nightShiftMultiplierEligible) {
      if (!nightShiftMultiplier?.toString()?.length || nightShiftMultiplier < 0 || nightShiftMultiplier / 100 > 10) {
        alerts.errorAlert(INVALID_NIGHT_SHIFT_MULTIPLIER[language])
      }

      if (!timeRegex.test(nightShiftBeginsAt)) alerts.errorAlert(
        INVALID_NS_START_TIME[language])

      if (!nightShiftContinuous && !timeRegex.test(nightShiftEndsAt)) alerts.errorAlert(
        INVALID_NS_END_TIME[language])
    }

    if (canSave) {
      await addNewShift({
        startYear: startDate.getFullYear(),
        startWeek: getWeekNumber(startDate),
        startMonth: startDate.getMonth(),
        startDay: startDate.getDate(),
        startTime,
        endYear: endDate.getFullYear(),
        endWeek: getWeekNumber(endDate),
        endMonth: endDate.getMonth(),
        endDay: endDate.getDate(),
        endTime,
        hoursWorked: workedHours,
        lunchHours: !lunchHours?.toString()?.length ? 0 : (lunchHours / 60),
        lunchPaid: lunchHoursPaid,
        normalRate: !hourlyRate?.toString()?.length ? 0 : parseFloat(hourlyRate),
        normalHours: !normalHours?.toString()?.length ? 0 : parseFloat(normalHours),
        currency,
        overTimeMultiplierEligible,
        overTimeMultiplier: !overTimeMultiplier?.toString()?.length ? 0 : overTimeMultiplier / 100,
        overTimeHours: workedHours - normalHours,
        nightShiftMultiplierEligible,
        nightShiftMultiplier: !nightShiftMultiplier?.toString()?.length ? 0 : nightShiftMultiplier / 100,
        nightShiftBeginsAt,
        nightShiftEndsAt,
        nightShiftContinuous,
        nightShiftHours,
        shiftSalary,
        description,
        company: companyOption.value,
        userId: auth?.userId,
      })
    }
  }

  if (isLoading || isCompaniesLoading) return

  if (isCompaniesSuccess) {
    const companiesIds = companies.ids
    const companiesEntities = companies.entities

    const userCompaniesOptions = !companiesIds?.length ? null :
    companiesIds.map((companyId) => {
      const company = companiesEntities[companyId]
      const companyOption = { value: company.id, label: company.name }
      return companyOption
    })

    const companiesOptions = userCompaniesOptions?.length 
      ? [NOT_SPECIFIED_COMPANY_OPTION, ...userCompaniesOptions]
      : [NOT_SPECIFIED_COMPANY_OPTION]

    return (
      <>
        <h2>{ADD_SHIFT[language]}</h2>
        <label className="form-control-label" htmlFor="start-date">
          {START_DATE[language]}
        </label>
        <Calendar
          name="start-date"
          id="start-date"
          maxDate={new Date()}
          minDate={new Date(2001, 0, 1)}
          onChange={(date) => {
            setStartDate(date)
            if (endDate < date) {
              setEndDate(date)
            }
          }}
          value={startDate}
        />
        <Row className="ml-0 mt-4">
          <InputField
            aria-label="Time"
            label={START_TIME[language]}
            type="time"
            name="start-time"
            id="start-time"
            value={startTime}
            onChange={(e) => {
              setStartTime(e.target.value)
            }}
          />
        </Row>
        <label className="form-control-label" htmlFor="end-date">
          {END_DATE[language]}
        </label>
        <Calendar
          name="end-date"
          maxDate={new Date()}
          minDate={startDate}
          onChange={(date) => setEndDate(date)}
          value={endDate}
        />
        <Row className="ml-0 mt-4">
          <InputField
            label={END_TIME[language]}
            aria-label="Time"
            type="time"
            name="end-time"
            id="end-time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </Row>
        <InputField
          id="hourly-rate"
          label={HOURLY_RATE[language]}
          type="text"
          value={hourlyRate}
          onChange={(e) => {
            const value = e.target.value

            if (DECIMAL_NUMBER_REGEX.test(value)) setHourlyRate(value)
          }}
        />
        <label className="form-control-label" htmlFor="currency">
          {CURRENCY[language]}
        </label>
        <Select
          className="mb-4"
          id="currency"
          name="currency"
          options={currencyOptions}
          value={{ value: currency, label: currency }}
          onChange={(option) => setCurrency(option.value)}
        />
        <Card className="mt-4">
          <CardHeader>
            <Row>
              <Col>
                <label onClick={() => setLunchHoursPaid((prev) => !prev)} className="form-control-label" htmlFor="lunch-hours-paid">
                  <span className="mr-2">{LUNCH_HOURS_PAID[language]}</span>
                  <FontAwesomeIcon
                    name="lunch-hours-paid"
                    size="xl"
                    icon={lunchHoursPaid ? faToggleOn : faToggleOff}
                    color={lunchHoursPaid ? "rgb(23, 152, 207)" : "grey"}
                  />
                </label>
              </Col>
            </Row>
          </CardHeader>
          {lunchHoursPaid ? null :
            <CardBody>
              {LUNCH_DEDUCTION_NB[language]}
              <InputField
                id="lunch-hours"
                label={LUNCH_HOURS[language]}
                type="text"
                value={lunchHours}
                onChange={(e) => {
                  const value = e.target.value
              
                  if (NUMBER_INPUT_REGEX.test(value)) {
                    if (value === "") setLunchHours(value)
                    else setLunchHours(parseInt(value))
                  }
                }}
              />
            </CardBody>      
          }
        </Card>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <label 
                  onClick={() => setOverTimeMultiplierEligible((prev) => !prev)}
                  className="form-control-label"
                  htmlFor="overtime-multiplier-eligible"
                >
                  <span className="mr-2">{OVERTIME_MULTIPLIER_ELIGIBLE[language]}</span>
                  <FontAwesomeIcon
                    name="overtime-multiplier-eligible"
                    size="xl"
                    icon={overTimeMultiplierEligible ? faToggleOn : faToggleOff}
                    color={overTimeMultiplierEligible ? "rgb(23, 152, 207)" : "grey"}
                  />
                </label>
              </Col>
            </Row>
          </CardHeader>
          {!overTimeMultiplierEligible ? null :
            <CardBody>
              <InputField
                id="overtime-multiplier"
                label={OVERTIME_MULTIPLIER[language]}
                type="text"
                value={overTimeMultiplier}
                onChange={(e) => {
                  const value = e.target.value
            
                  if (NUMBER_INPUT_REGEX.test(value)) {
                    if (value === "") setOverTimeMultiplier(value)
                    else setOverTimeMultiplier(parseInt(value))
                  }
                }}
              />        
              <InputField
                id="normal-hours"
                label={NORMAL_HOURS_BEFORE_OVERTIME[language]}
                type="text"
                value={normalHours}
                onChange={(e) => {
                  const value = e.target.value
                  if (DECIMAL_NUMBER_REGEX.test(value)) setNormalHours(value)
                }}
              />
            </CardBody>
          }
        </Card>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <label
                  onClick={() => setNightShiftMultiplierEligible((prev) => !prev)}
                  className="form-control-label mt-2"
                  htmlFor="night-shift-multiplier-eligible"
                >
                  <span className="mr-2">{NIGHT_SHIFT_MULTIPLIER_ELIGIBLE[language]}</span>
                  <FontAwesomeIcon
                    name="night-shift-multiplier-eligible"
                    size="xl"
                    icon={nightShiftMultiplierEligible ? faToggleOn : faToggleOff}
                    color={nightShiftMultiplierEligible ? "rgb(23, 152, 207)" : "grey"}
                  />
                </label>
              </Col>
            </Row>
          </CardHeader>
          {!nightShiftMultiplierEligible ? null :
            <CardBody>
              <Row>
                <Col>
                  <label
                    onClick={() => setNightShiftContinuous((prev) => !prev)}
                    className="form-control-label mt-2"
                    htmlFor="night-shift-continuous"
                  >
                    <span className="mr-2">{NIGHT_SHIFT_CONTINUOUS[language]}</span>
                    <FontAwesomeIcon
                      name="night-shift-continuous"
                      id="night-shift-continuous"
                      size="xl"
                      icon={nightShiftContinuous ? faToggleOn : faToggleOff}
                      color={nightShiftContinuous ? "rgb(23, 152, 207)" : "grey"}
                    />
                  </label>
                </Col>
              </Row>
              <InputField
                id="night-shift-multiplier"
                label={NIGHT_SHIFT_MULTIPLIER[language]}
                type="text"
                value={nightShiftMultiplier}
                onChange={(e) => {
                  const value = e.target.value
            
                  if (NUMBER_INPUT_REGEX.test(value)) {
                    if (value === "") setNightShiftMultiplier(value)
                    else setNightShiftMultiplier(parseInt(value))
                  }
                }}
              />
              <Row className="ml-0">
                <InputField
                  label={NIGHT_SHIFT_BEGINS_AT[language]}
                  aria-label="Time"
                  type="time"
                  name="night-shift-begins-at"
                  id="night-shift-begins-at"
                  value={nightShiftBeginsAt}
                  onChange={(e) => {
                    setNightShiftBeginsAt(e.target.value)
                  }}
                />
              </Row>
              <Row className="ml-0">
                <InputField
                  label={NIGHT_SHIFT_ENDS_AT[language]}
                  aria-label="Time"
                  type="time"
                  name="night-shift-ends-at"
                  id="night-shift-ends-at"
                  value={nightShiftEndsAt}
                  onChange={(e) => {
                    setNightShiftEndsAt(e.target.value)
                  }}
                />
              </Row>
            </CardBody>       
          }
        </Card>
        <label className="form-control-label" htmlFor="description">
          {DESCRIPTION[language]} ({OPTIONAL[language]})
        </label>
        <Input
          className="mb-4"
          id="description"
          name="description"
          maxLength={255}
          type="textarea"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <label className="form-control-label" htmlFor="default-company">
          {COMPANY[language]}
        </label>
        <Select
          className="mb-4"
          id="default-company"
          name="default-company"
          options={companiesOptions}
          value={companyOption}
          onChange={(option) => setCompanyOption(option)}
        />
        <Row>
          <Col>
            <Button
              onClick={() => handleSaveShiftClicked()}
              className="mr-4 mt-2"
              style={{ backgroundColor: '#08c792', color: 'white' }}
              type="submit"
            >
              {ADD_SHIFT[language]}
            </Button>
          </Col>
          <Col>
            <Row className="text-right">
              <Col>
                {DAILY_AMOUNT_EARNED[language]}
              </Col>
            </Row>
            <Row className="text-right">
              <Col>
                <h3>
                  {shiftSalary} {currency}
                </h3>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }

  return
}

export default NewShiftForm
