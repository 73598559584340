import { createSlice } from "@reduxjs/toolkit"
import { Language } from "../../assets/texts/languages.ts"

const languageSlice = createSlice({
  name: 'language',
  initialState: { language: Language.English },
  reducers: {
    setLanguage: (state, action) => {
      const { language } = action.payload
      state.language = language
    },
  }
})

export const { setLanguage } = languageSlice.actions

export default languageSlice.reducer

export const selectCurrentLanguage = (state) => state.language.language
