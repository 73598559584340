import { Language } from './languages.ts';

export const LOGIN = {
  [Language.English]: 'Log in',
  [Language.Estonian]: 'Logi sisse',
  [Language.Norwegian]: 'Logg inn',
};

export const REGISTER = {
  [Language.English]: 'Register',
  [Language.Estonian]: 'Registreeri',
  [Language.Norwegian]: 'Registrer deg',
};

export const DONT_HAVE_AN_ACCOUNT = {
  [Language.English]: `Don't have an account?`,
  [Language.Estonian]: 'Konto puudub?',
  [Language.Norwegian]: 'Har du ikke en konto?',
};

export const ALREADY_HAVE_AN_ACCOUNT = {
  [Language.English]: `Already have an account?`,
  [Language.Estonian]: 'Konto olemas?',
  [Language.Norwegian]: 'Har du allerede en konto?',
};

export const REGISTER_NEW_ACCOUNT = {
  [Language.English]: `Register a New Account`,
  [Language.Estonian]: 'Registreeri uus konto',
  [Language.Norwegian]: 'Registrer en ny konto',
};

export const USERNAME = {
  [Language.English]: 'Username',
  [Language.Estonian]: 'Kasutajanimi',
  [Language.Norwegian]: 'Brukernavn',
};

export const NAME = {
  [Language.English]: 'Full Name',
  [Language.Estonian]: 'Ees- ja Perekonnanimi',
  [Language.Norwegian]: 'For- og Etternavn',
};

export const USERNAME_OR_EMAIL = {
  [Language.English]: 'Username or Email',
  [Language.Estonian]: 'Kasutajanimi või email',
  [Language.Norwegian]: 'Brukernavn eller email',
};

export const STAY_LOGGED_IN = {
  [Language.English]: 'Stay Logged In',
  [Language.Estonian]: 'Jää sisselogituks',
  [Language.Norwegian]: 'Forbli innlogget',
};

export const PASSWORD = {
  [Language.English]: 'Password',
  [Language.Estonian]: 'Parool',
  [Language.Norwegian]: 'Passord',
};

export const CONFIRM_PASSWORD = {
  [Language.English]: 'Confirm Password',
  [Language.Estonian]: 'Kinnita Parool',
  [Language.Norwegian]: 'Bekreft passord',
};

export const CURRENT_PASSWORD = {
  [Language.English]: 'Current Password',
  [Language.Estonian]: 'Praegune Parool',
  [Language.Norwegian]: 'Gjeldende passord',
};

export const NEW_PASSWORD = {
  [Language.English]: 'New Password (Optional)',
  [Language.Estonian]: 'Uus Parool (Valikuline)',
  [Language.Norwegian]: 'Nytt passord (Valgfritt)',
};

export const CONFIRM_NEW_PASSWORD = {
  [Language.English]: 'Confirm New Password (Optional)',
  [Language.Estonian]: 'Kinnita Uus Parool (Valikuline)',
  [Language.Norwegian]: 'Bekreft nytt passord (Valgfritt)',
};

export const ADD_SHIFT = {
  [Language.English]: 'Add shift',
  [Language.Estonian]: 'Lisa vahetus',
  [Language.Norwegian]: 'Legge til skift',
};

export const ADD_COMPANY = {
  [Language.English]: 'Add Company',
  [Language.Estonian]: 'Lisa Firma',
  [Language.Norwegian]: 'Legg til Firmaet',
};

export const START_DATE = {
  [Language.English]: 'Start Date',
  [Language.Estonian]: 'Alguskuupäev',
  [Language.Norwegian]: 'Startdato',
};

export const START_TIME = {
  [Language.English]: 'Start Time',
  [Language.Estonian]: 'Alguskellaaeg',
  [Language.Norwegian]: 'Starttid',
};

export const END_DATE = {
  [Language.English]: 'End Date',
  [Language.Estonian]: 'Lõppkuupäev',
  [Language.Norwegian]: 'Sluttdato',
};

export const END_TIME = {
  [Language.English]: 'End Time',
  [Language.Estonian]: 'Lõppkellaaeg',
  [Language.Norwegian]: 'Sluttid',
};

export const HOURS = {
  [Language.English]: 'Hours',
  [Language.Estonian]: 'Tunnid',
  [Language.Norwegian]: 'Timer',
};

export const WEEK = {
  [Language.English]: 'Week 1-53',
  [Language.Estonian]: 'Nädal 1-53',
  [Language.Norwegian]: 'Uke 1-53',
};

export const PDF_WEEK = {
  [Language.English]: 'Week',
  [Language.Estonian]: 'Nädal',
  [Language.Norwegian]: 'Uke',
};

export const MONTH = {
  [Language.English]: 'Month',
  [Language.Estonian]: 'Kuu',
  [Language.Norwegian]: 'Måned',
};

export const YEAR = {
  [Language.English]: 'Year',
  [Language.Estonian]: 'Aasta',
  [Language.Norwegian]: 'År',
};

export const EXCEL_GUIDE = {
  [Language.English]: 'Excel Guide',
  [Language.Estonian]: 'Exceli Juhend',
  [Language.Norwegian]: 'Excel-Veiledning',
};

export const EXCEL_FIELDS_EXPLAINED = {
  [Language.English]: 'Explanation of Excel Columns',
  [Language.Estonian]: 'Exceli Tulpade Selgitus',
  [Language.Norwegian]: 'Forklaring av Excel-Kolonner',
};

export const TOTAL_HOURS_EXPLANATION = {
  [Language.English]: 'Total amount of hours worked. Lunch hours are not deducted.',
  [Language.Estonian]: 'Tundide kogusumma. Lõuna tunnid ei ole maha arvestatud.',
  [Language.Norwegian]: 'Totalt antall timer arbeidet. Lunsjtimer trekkes ikke fra.',
};

export const TOTAL_LUNCH_EXPLANATION = {
  [Language.English]: 'Total amount of hours spent on lunch.',
  [Language.Estonian]: 'Lõunal veetud tundide kogusumma.',
  [Language.Norwegian]: 'Totalt antall timer brukt på lunsj.',
};

export const TOTAL_NS_EXPLANATION = {
  [Language.English]: "Total amount of hours worked during hours where night shift salary multiplier is applicable.",
  [Language.Estonian]: 'Kogusumma tundidest, mis on töötatud ajal, mil öötöötasu kehtib.',
  [Language.Norwegian]: 'Totalt antall timer jobbet i timer der nattskiftlønnsmultiplikator er aktuelt.',
};

export const TOTAL_SALARY_EXPLANATION = {
  [Language.English]: "Gross salary for the entire period. Overtime rates, night shift rates, lunch time deductions accounted for where applicable. If the company does not use summarized overtimes and the employee has entered their shifts according to the company's guidelines, then this is the correct gross salary for the hours they've worked. Sick days and vacation days are not accounted for.",
  [Language.Estonian]: 'Brutotöötasu terve perioodi eest. Ületunnitasud, öötöötasud ning lõunatundide mahaarvestused tehtud seal, kus need kehtivad. Kui tööandja ei kasuta summeeritud ületunde ning töötaja on kõik vahetused vastavalt tööandja juhistele sisestanud, siis on see korrektne brutotöötasu töötatud tundide eest. Haiguslehed, puhkusepäevad jms pole sisse arvestatud.',
  [Language.Norwegian]: 'Bruttolønn for hele perioden. Overtidssatser, nattskiftsatser, lunsjtidsfradrag regnskapsført der det er aktuelt. Dersom bedriften ikke bruker oppsummert overtid og den ansatte har gått inn i sine skift i henhold til bedriftens retningslinjer, er dette riktig bruttolønn for timene de har jobbet. Det regnskapsføres ikke sykedager og feriedager.',
};

export const TOTAL_OT_EXPLANATION = {
  [Language.English]: "Total amount of hours worked past or not worked until daily normal hours. Used for summarized overtime hours. If normal hours are set to 8, then working for 5 hours gives you -3, while working 10 hours gives you +2 in the overall of these hours.",
  [Language.Estonian]: 'Kogusumma tundidest, mis on töötaud üle või alla päeva normtundidest. Kasutada summeeritud ületundide arvestusel. Kui vahetuse normtunnid on määratud 8 peale, siis vahetusel 5 töötatud tundi annab -3 ning 10 töötatud tundi annaks +2 antud tundide kogusummas.',
  [Language.Norwegian]: 'Totalt antall timer arbeidet tidligere eller ikke jobbet før daglig normaltid. Brukes til oppsummerte overtidstimer. Hvis normale arbeidstimer er satt til 8, vil det å jobbe i 5 timer gi deg -3, mens det å jobbe 10 timer gir deg +2 i totalen av disse timene.',
};

export const NAME_EXPLANATION = {
  [Language.English]: "Employee's name. Employee can change it at any time in settings.",
  [Language.Estonian]: 'Töötaja nimi. Töötaja saab seda igal ajal muuta.',
  [Language.Norwegian]: 'Arbeiders navn. Arbeider kan endre det når som helst i innstillingene.',
};

export const USERNAME_EXPLANATION = {
  [Language.English]: "Employee's username. Unique and cannot be changed - in case two employees have the same name.",
  [Language.Estonian]: 'Töötaja kasutajanimi. Unikaalne ning seda pole võimalik muuta - juhuks kui kahel töötajal on sama nimi.',
  [Language.Norwegian]: 'Arbeiders brukernavn. Unik og kan ikke endres - i tilfelle to arbeidere har samme navn.',
};

export const START_YEAR_EXPLANATION = {
  [Language.English]: "The year the shift started.",
  [Language.Estonian]: 'Aasta, mil vahetus algas.',
  [Language.Norwegian]: 'Året skiftet startet.',
};

export const START_MONTH_EXPLANATION = {
  [Language.English]: "The month the shift started (1-12).",
  [Language.Estonian]: 'Kuu, mil vahetus algas (1-12).',
  [Language.Norwegian]: 'Måneden skiftet startet (1-12).',
};

export const START_WEEK_EXPLANATION = {
  [Language.English]: "The week of the year when the shift started (1-53).",
  [Language.Estonian]: 'Aasta nädal, mil vahetus algas (1-53).',
  [Language.Norwegian]: 'Uken i året da skiftet startet (1-53).',
};

export const START_DAY_EXPLANATION = {
  [Language.English]: "The day of the month when the shift started (1-31).",
  [Language.Estonian]: 'Kuupäev, mil vahetus algas (1-31).',
  [Language.Norwegian]: 'Dagen i måneden da skiftet startet (1-31).',
};

export const START_TIME_EXPLANATION = {
  [Language.English]: "The time of the day when the shift started (00:00 - 23:59).",
  [Language.Estonian]: 'Kellaaeg, mil vahetus algas (00:00 - 23:59)',
  [Language.Norwegian]: 'Tidspunktet på dagen da skiftet startet (00:00 - 23:59).',
};

export const END_YEAR_EXPLANATION = {
  [Language.English]: "The year the shift ended.",
  [Language.Estonian]: 'Aasta, mil vahetus lõppes.',
  [Language.Norwegian]: 'Året skiftet tok slutt.',
};

export const END_MONTH_EXPLANATION = {
  [Language.English]: "The month the shift ended (1-12).",
  [Language.Estonian]: 'Kuu, mil vahetus lõppes (1-12).',
  [Language.Norwegian]: 'Måneden skiftet sluttet (1-12).',
};

export const END_WEEK_EXPLANATION = {
  [Language.English]: "The week of the year when the shift ended (1-53).",
  [Language.Estonian]: 'Aasta nädal, mil vahetus lõppes (1-53).',
  [Language.Norwegian]: 'Uken i året da skiftet ble avsluttet (1-53).',
};

export const END_DAY_EXPLANATION = {
  [Language.English]: "The day of the month when the shift ended (1-31).",
  [Language.Estonian]: 'Kuupäev, mil vahetus lõppes (1-31).',
  [Language.Norwegian]: 'Dagen i måneden da skiftet ble avsluttet (1-31).',
};

export const END_TIME_EXPLANATION = {
  [Language.English]: "The time of the day when the shift ended (00:00 - 23:59).",
  [Language.Estonian]: 'Kellaaeg, mil vahetus lõppes (00:00 - 23:59).',
  [Language.Norwegian]: 'Tidspunktet på dagen da skiftet ble avsluttet (00:00 - 23:59).',
};

export const HOURS_WORKED_EXPLANATION = {
  [Language.English]: "Hours between the times when the shift started and when the shift ended.",
  [Language.Estonian]: 'Tunnid vahetuse algusaja ning lõppaja vahel.',
  [Language.Norwegian]: 'Timer mellom tidspunktene skiftet startet og skiftet sluttet.',
};

export const LUNCH_HOURS_EXPLANATION = {
  [Language.English]: "Hours spent on lunch time.",
  [Language.Estonian]: 'Lõunal veedetud tunnid.',
  [Language.Norwegian]: 'Timer brukt på lunsjtid.',
};

export const LUNCH_PAID_EXPLANATION = {
  [Language.English]: "Whether lunch time is paid or not. TRUE = paid, FALSE = not paid.",
  [Language.Estonian]: 'Kas lõuna on tasustatud või mitte. TRUE = tasustatud, FALSE = ei ole tasustatud.',
  [Language.Norwegian]: 'Om lunsjtid er betalt eller ikke. TRUE = betalt, FALSE = ikke betalt.',
};

export const NORMAL_HOURS_EXPLANATION = {
  [Language.English]: "Amount of hours in a shift that marks the point after which overtime is being counted.",
  [Language.Estonian]: 'Tundide arv vahetusel, peale mida hakatakse arvestama ületunde.',
  [Language.Norwegian]: 'Antall timer i et skift som markerer punktet hvor overtid telles.',
};

export const NORMAL_RATE_EXPLANATION = {
  [Language.English]: "Base hourly salary.",
  [Language.Estonian]: 'Baastöötasu tunnis.',
  [Language.Norwegian]: 'Grunntimelønn.',
};

export const CURRENCY_EXPLANATION = {
  [Language.English]: "Currency the salary is paid in for given shift.",
  [Language.Estonian]: 'Valuuta, milles vahetuse eest palka makstakse.',
  [Language.Norwegian]: 'Valuta lønnen betales i for gitt skift.',
};

export const OT_MULTIPLIER_EXPLANATION = {
  [Language.English]: "Multiplier for overtime hours (0.5 = 50% higher rate).",
  [Language.Estonian]: 'Kordaja ületunnitasu eest (0.5 = 50% kõrgem tasu).',
  [Language.Norwegian]: 'Multiplikator for overtidstimer (0.5 = 50% høyere sats).',
};

export const OT_MULTIPLIER_ELIGIBLE_EXPLANATION = {
  [Language.English]: "Whether overtime is paid at a higher rate or not. TRUE = paid at a higher rate, FALSE = paid at base rate.",
  [Language.Estonian]: 'Kas ületundide eest makstakse kõrgemat tasu või mitte. TRUE = makstakse kõrgemat tasu, FALSE = makstakse baastöötasu.',
  [Language.Norwegian]: 'Om overtid betales med høyere sats eller ikke. TRUE = betalt til en høyere sats, FALSE = betalt til grunnsats.',
};

export const OT_HOURS_EXPLANATION = {
  [Language.English]: "Amount of hours worked above the daily norm.",
  [Language.Estonian]: 'Päevanormist üle töötatud tunnid.',
  [Language.Norwegian]: 'Arbeidstimer over dagsnormen.',
};

export const NS_MULTIPLIER_ELIGIBLE_EXPLANATION = {
  [Language.English]: "Whether night shift hours are paid at a higher rate or not. TRUE = paid at a higher rate, FALSE = paid at base rate.",
  [Language.Estonian]: 'Kas öötundide eest makstakse kõrgemat tasu või mitte. TRUE = makstakse kõrgemat tasu, FALSE = makstakse baastöötasu.',
  [Language.Norwegian]: 'Om nattevakttimer betales med høyere sats eller ikke. TRUE = betalt til en høyere sats, FALSE = betalt til grunnsats.',
};

export const NS_CONTINUOUS_EXPLANATION = {
  [Language.English]: "Whether night shift multiplier stays until the end of the shift when an employee works past night shift hours into the day. TRUE = continues until the end of the shift, FALSE = ends when the night shift end time is reached and may start again only if the employee reaches the next night shift during that same shift again.",
  [Language.Estonian]: 'Kas kõrgem öötunnitasu jääb kehtima vahetuse lõpuni juhul kui töötaja töötab öö läbi järgmisesse päeva. TRUE = jääb kehtima, FALSE = kõrgem tasu lõpeb öötööaja lõppedes ning võib uuesti alata vaid siis, kui samal vahetusel algab järgmine öötööaeg.',
  [Language.Norwegian]: 'Hvorvidt nattskiftmultiplikator forblir til slutten av skiftet når en ansatt jobber over nattevakttimer utover dagen. TRUE = fortsetter til slutten av skiftet, FALSE = slutter når sluttidspunktet for nattskift er nådd og kan bare starte på nytt hvis den ansatte når neste nattskift i samme skift igjen.',
};

export const NS_BEGINS_AT_EXPLANATION = {
  [Language.English]: "The time of the day when night shift starts (00:00 - 23:59).",
  [Language.Estonian]: 'Kellaaeg, mil öötööaeg algab (00:00 - 23:59).',
  [Language.Norwegian]: 'Tidspunktet på døgnet når nattskift starter (00:00 - 23:59).',
};

export const NS_ENDS_AT_EXPLANATION = {
  [Language.English]: "The time of the day when night shift ends (00:00 - 23:59).",
  [Language.Estonian]: 'Kellaaeg, mil öötööaeg lõpeb (00:00 - 23:59).',
  [Language.Norwegian]: 'Tidspunktet på døgnet når nattskiftet avsluttes (00:00 - 23:59).',
};

export const NS_MULTIPLIER_EXPLANATION = {
  [Language.English]: "Multiplier for night shift hours (0.5 = 50% higher rate).",
  [Language.Estonian]: 'Kordaja öötöötundide eest (0.5 = 50% kõrgem tasu).',
  [Language.Norwegian]: 'Multiplikator for nattskifttimer (0,5 = 50% høyere sats).',
};

export const NS_HOURS_EXPLANATION = {
  [Language.English]: "Hours where night shift multiplier was applicable and the employee had turned on it's eligibility (nightShiftMultiplierEligible = TRUE).",
  [Language.Estonian]: 'Tunnid, mil kõrgem öötöötasu kehtis ning töötaja oli selle sisse lülitanud (nightShiftMultiplierEligible = TRUE).',
  [Language.Norwegian]: 'Timer der nattskiftmultiplikator var gjeldende og den ansatte hadde slått på kvalifikasjonen (nightShiftMultiplierEligible = TRUE).',
};

export const SHIFT_SALARY_EXPLANATION = {
  [Language.English]: "Gross salary for said shift.",
  [Language.Estonian]: 'Antud vahetuse brutotöötasu.',
  [Language.Norwegian]: 'Bruttolønn for nevnte skift.',
};

export const DESCRIPTION_EXPLANATION = {
  [Language.English]: "Description for the shift, entered by the employee (optional).",
  [Language.Estonian]: 'Vahetuse kirjeldus, sisestatud töötaja poolt (valikuline).',
  [Language.Norwegian]: 'Beskrivelse for skiftet, lagt inn av den ansatte (valgfritt).',
};

export const WEEKLY_SHIFTS = {
  [Language.English]: 'Weekly Shifts',
  [Language.Estonian]: 'Nädala Kaupa',
  [Language.Norwegian]: 'Ukentlige skift',
};

export const AMOUNT_OF_HOURS = {
  [Language.English]: 'Hours',
  [Language.Estonian]: 'Tundi',
  [Language.Norwegian]: 'Timer',
};

export const AMOUNT_OF_MINUTES = {
  [Language.English]: 'Minutes',
  [Language.Estonian]: 'Minutit',
  [Language.Norwegian]: 'Minutter',
};

export const NORMAL_HOURS_BEFORE_OVERTIME = {
  [Language.English]: 'Normal Hours Before Overtime',
  [Language.Estonian]: 'Tavatunnid Enne Ületunde',
  [Language.Norwegian]: 'Timer før Overtid',
};

export const LUNCH_HOURS_PAID = {
  [Language.English]: 'Lunch Hours Paid',
  [Language.Estonian]: 'Tasustatud Lõuna',
  [Language.Norwegian]: 'Lunsjtimer Betalt',
};

export const LUNCH_HOURS = {
  [Language.English]: 'Lunch Time in Minutes',
  [Language.Estonian]: 'Lõuna Minutites',
  [Language.Norwegian]: 'Lunsjtid i Minutter',
};

export const IN_TOTAL = {
  [Language.English]: 'In Total',
  [Language.Estonian]: 'Kokku',
  [Language.Norwegian]: 'Totalt',
};

export const OF_WHICH = {
  [Language.English]: 'of which',
  [Language.Estonian]: 'millest',
  [Language.Norwegian]: 'hvorav',
};

export const DOWNLOAD_SUMMARY = {
  [Language.English]: 'Download Summary',
  [Language.Estonian]: 'Lae Alla Kokkuvõte',
  [Language.Norwegian]: 'Last Ned Sammendrag',
};

export const OVERTIME_HOURS = {
  [Language.English]: 'Overtime hours',
  [Language.Estonian]: 'Ületunnid',
  [Language.Norwegian]: 'Overtidstimer',
};

export const PAID_OVERTIME_HOURS = {
  [Language.English]: 'Paid overtime hours',
  [Language.Estonian]: 'Tasustatud ületunnid',
  [Language.Norwegian]: 'Betalte overtidstimer',
};

export const HOURLY_RATE = {
  [Language.English]: 'Base Hourly Rate',
  [Language.Estonian]: 'Baastunnitasu',
  [Language.Norwegian]: 'Grunntimepris',
};

export const CURRENCY = {
  [Language.English]: 'Currency',
  [Language.Estonian]: 'Valuuta',
  [Language.Norwegian]: 'Valuta',
};

export const OVERTIME_MULTIPLIER_ELIGIBLE = {
  [Language.English]: 'Overtime Percentage Eligible',
  [Language.Estonian]: 'Ületunnid Kõrgema Tunnitasuga',
  [Language.Norwegian]: 'Overtidsbetaling Berettiget',
};

export const OVERTIME_MULTIPLIER = {
  [Language.English]: 'Overtime Rate Percentage',
  [Language.Estonian]: 'Ületunnitasu Protsent',
  [Language.Norwegian]: 'Overtidssats Prosent',
};

export const NIGHT_SHIFT_MULTIPLIER_ELIGIBLE = {
  [Language.English]: 'Night Shift Percentage Eligible',
  [Language.Estonian]: 'Öötunnid Kõrgema Tunnitasuga',
  [Language.Norwegian]: 'Nattskiftsats Kvalifisert',
};

export const NIGHT_SHIFT_MULTIPLIER = {
  [Language.English]: 'Night Shift Rate Percentage',
  [Language.Estonian]: 'Öötunnitasu Protsent',
  [Language.Norwegian]: 'Nattskiftsatsprosent',
};

export const NIGHT_SHIFT_BEGINS_AT = {
  [Language.English]: 'Night Shift Begins At',
  [Language.Estonian]: 'Öötöö Algusaeg',
  [Language.Norwegian]: 'Nattskift Begynner Kl.',
};

export const NIGHT_SHIFT_ENDS_AT = {
  [Language.English]: 'Night Shift Ends At',
  [Language.Estonian]: 'Öötöö Lõppaeg',
  [Language.Norwegian]: 'Nattskift Avsluttes Kl.',
};

export const NIGHT_SHIFT_CONTINUOUS = {
  [Language.English]: 'Night shift multiplier continues until the end of shift',
  [Language.Estonian]: 'Öötöö jääb kestma vahetuse lõpuni',
  [Language.Norwegian]: 'Nattskiftmultiplikator fortsetter til slutten av skiftet',
};

export const NIGHT_SHIFT_HOURS = {
  [Language.English]: 'Night shift hours',
  [Language.Estonian]: 'Öötöö tunnid',
  [Language.Norwegian]: 'Nattevakttimer',
};

export const PAID_NIGHT_SHIFT_HOURS = {
  [Language.English]: 'Paid night shift hours',
  [Language.Estonian]: 'Tasustatud öötöö tunnid',
  [Language.Norwegian]: 'Betalte nattevakttimer',
};

export const CONTRACT_TYPE = {
  [Language.English]: 'Contract Type',
  [Language.Estonian]: 'Lepingutüüp',
  [Language.Norwegian]: 'Kontrakt Type',
};

export const COMPANY = {
  [Language.English]: 'Company',
  [Language.Estonian]: 'Firma',
  [Language.Norwegian]: 'Firma',
};

export const DESCRIPTION = {
  [Language.English]: 'Description',
  [Language.Estonian]: 'Kirjeldus',
  [Language.Norwegian]: 'Beskrivelse',
};

export const OPTIONAL = {
  [Language.English]: 'Optional',
  [Language.Estonian]: 'Valikuline',
  [Language.Norwegian]: 'Valgfri',
};

export const DATE = {
  [Language.English]: 'Date',
  [Language.Estonian]: 'Kuupäev',
  [Language.Norwegian]: 'Dato',
};

export const LUNCH = {
  [Language.English]: 'Lunch',
  [Language.Estonian]: 'Lõuna',
  [Language.Norwegian]: 'Lunsj',
};

export const LUNCH_TIMES = {
  [Language.English]: 'Lunch Hours',
  [Language.Estonian]: 'Lõuna Tunnid',
  [Language.Norwegian]: 'Lunsjtimer',
};

export const DOWNLOAD = {
  [Language.English]: 'Download',
  [Language.Estonian]: 'Lae alla',
  [Language.Norwegian]: 'Last ned',
};

export const REMOVE_COMPANY = {
  [Language.English]: 'Remove Company',
  [Language.Estonian]: 'Eemalda Firma',
  [Language.Norwegian]: 'Fjern Firma',
};

export const SHIFT_SALARY = {
  [Language.English]: 'Shift salary',
  [Language.Estonian]: 'Vahetuse tasu',
  [Language.Norwegian]: 'Skift lønn',
};

export const SALARY = {
  [Language.English]: 'Salary',
  [Language.Estonian]: 'Palk',
  [Language.Norwegian]: 'Lønn',
};

export const DAILY_AMOUNT_EARNED = {
  [Language.English]: 'Amount Earned',
  [Language.Estonian]: 'Päevateenistus',
  [Language.Norwegian]: 'Dagslønn Opptjent',
};

export const LUNCH_DEDUCTION_NB = {
  [Language.English]: 'NB! Lunch deduction in salary will be made according to the base hourly rate specified. Add or subtract lunch minutes accordingly if the rate is something different in this case.',
  [Language.Estonian]: 'NB! Palk lõuna eest arvestatakse maha vastavalt sisestatud tunnitasule. Juhul kui mahaarvestus lõuna eest käib muu tasu järgi, siis lisa või eemalda minuteid vastavalt.',
  [Language.Norwegian]: 'NB! Lunsjtrekk i lønn vil skje etter angitt grunntimesats. Legg til eller trekk fra lunsjminutter i henhold til dette hvis prisen er noe annerledes i dette tilfellet.',
};

export const MY_SHIFTS = {
  [Language.English]: 'My Shifts',
  [Language.Estonian]: 'Minu Vahetused',
  [Language.Norwegian]: 'Mine Skift',
}

export const ALL_COMPANIES = {
  [Language.English]: 'All Companies',
  [Language.Estonian]: 'Kõik Firmad',
  [Language.Norwegian]: 'Alle Firmaer',
}

export const JUST_SHIFTS = {
  [Language.English]: 'Shifts',
  [Language.Estonian]: 'Vahetused',
  [Language.Norwegian]: 'Skift',
}

export const JANUARY = {
  [Language.English]: 'January',
  [Language.Estonian]: 'Jaanuar',
  [Language.Norwegian]: 'Januar',
}

export const FEBRUARY = {
  [Language.English]: 'February',
  [Language.Estonian]: 'Veebruar',
  [Language.Norwegian]: 'Februar',
}

export const MARCH = {
  [Language.English]: 'March',
  [Language.Estonian]: 'Märts',
  [Language.Norwegian]: 'Mars',
}

export const APRIL = {
  [Language.English]: 'April',
  [Language.Estonian]: 'Aprill',
  [Language.Norwegian]: 'April',
}

export const MAY = {
  [Language.English]: 'May',
  [Language.Estonian]: 'Mai',
  [Language.Norwegian]: 'Mai',
}

export const JUNE = {
  [Language.English]: 'June',
  [Language.Estonian]: 'Juuni',
  [Language.Norwegian]: 'Juni',
}

export const JULY = {
  [Language.English]: 'July',
  [Language.Estonian]: 'Juuli',
  [Language.Norwegian]: 'Juli',
}

export const AUGUST = {
  [Language.English]: 'August',
  [Language.Estonian]: 'August',
  [Language.Norwegian]: 'August',
}

export const SEPTEMBER = {
  [Language.English]: 'September',
  [Language.Estonian]: 'September',
  [Language.Norwegian]: 'September',
}

export const OCTOBER = {
  [Language.English]: 'October',
  [Language.Estonian]: 'Oktoober',
  [Language.Norwegian]: 'Oktober',
}

export const NOVEMBER = {
  [Language.English]: 'November',
  [Language.Estonian]: 'November',
  [Language.Norwegian]: 'November',
}

export const DECEMBER = {
  [Language.English]: 'December',
  [Language.Estonian]: 'Detsember',
  [Language.Norwegian]: 'Desember',
}

export const CLOSE = {
  [Language.English]: 'Close',
  [Language.Estonian]: 'Sulge',
  [Language.Norwegian]: 'Lukk',
}

export const SHIFT = {
  [Language.English]: 'Shift',
  [Language.Estonian]: 'Vahetus',
  [Language.Norwegian]: 'Skift',
}

export const SHIFTS = {
  [Language.English]: 'Shifts',
  [Language.Estonian]: 'Vahetust',
  [Language.Norwegian]: 'Skift',
}

export const INSERTED = {
  [Language.English]: 'Inserted',
  [Language.Estonian]: 'Sisestatud',
  [Language.Norwegian]: 'Satt Inn',
}

export const START_SHIFT = {
  [Language.English]: 'Start Shift',
  [Language.Estonian]: 'Alusta Vahetust',
  [Language.Norwegian]: 'Start Skiftet',
}

export const END_SHIFT = {
  [Language.English]: 'End Shift',
  [Language.Estonian]: 'Lõpeta Vahetus',
  [Language.Norwegian]: 'Avslutt Skiftet',
}

export const SHIFT_ADDED_SUCCESSFULLY = {
  [Language.English]: 'Shift added successfully',
  [Language.Estonian]: 'Vahetus edukalt lisatud',
  [Language.Norwegian]: 'Skift ble lagt til',
}

export const TIMER_RUNNING = {
  [Language.English]: 'The timer is running',
  [Language.Estonian]: 'Taimer käib',
  [Language.Norwegian]: 'Tidtakeren går',
}

export const END_TIMER_TEXT = {
  [Language.English]: 'The timer only prefills the start and end dates and times. Please do not forget to fill the rest of the form and save the shift after pressing on the "End Shift" button. The timer will stay on until you have pressed the button, regardless of whether you have closed the browser window and/or logged out.',
  [Language.Estonian]: 'Taimer kõigest eeltäidab algus- ning lõppkuupäeva ning kellaajad. Palun ärge unustage täita ning salvestada ülejäänud vorm peale "Lõpeta Vahetus" nupule vajutamist. Taimer jääb käima kuniks olete nupule vajutanud, olenemata sellest kas olete vahepeal veebilehitseja akna kinni pannud ja/või välja loginud.',
  [Language.Norwegian]: 'Tidtakeren forhåndsfyller kun start- og sluttdatoene og -tidene. Ikke glem å fylle ut resten av skjemaet og lagre skiftet etter å ha trykket på "Avslutt Skiftet"-knappen. Tidtakeren vil stå på til du har trykket på knappen, uansett om du har lukket nettleservinduet og/eller logget ut.',
}

export const SUCCESS = {
  [Language.English]: 'Success!',
  [Language.Estonian]: 'Korras!',
  [Language.Norwegian]: 'Fint!',
}

export const VIEW_DETAILS = {
  [Language.English]: 'View Details',
  [Language.Estonian]: 'Vaata Lähemalt',
  [Language.Norwegian]: 'Vis Detaljer',
}

export const VIEW_SHIFTS = {
  [Language.English]: 'View Shifts',
  [Language.Estonian]: 'Vaata Vahetusi',
  [Language.Norwegian]: 'Vis Skift',
}

export const UPDATE_UUID = {
  [Language.English]: 'Generate a New ID',
  [Language.Estonian]: 'Genereeri uus ID',
  [Language.Norwegian]: 'Generer en ny ID',
}

export const UPDATE_USER = {
  [Language.English]: 'Update User',
  [Language.Estonian]: 'Salvesta',
  [Language.Norwegian]: 'Oppdater Brukerkontoen',
}

export const COMPANY_ADD_ID = {
  [Language.English]: 'Unique ID for employees to add you as an employer',
  [Language.Estonian]: 'Unikaalne ID töötajatele, et nad saaksid teid oma tööandjaks lisada',
  [Language.Norwegian]: 'Unik ID for ansatte for å legge deg til som arbeidsgiver',
}

export const USER_ADD_ID = {
  [Language.English]: 'Unique ID provided by your employer to add them to your list of companies',
  [Language.Estonian]: 'Unikaalne ID tööandjalt, et saaksite firma enda kontole lisada',
  [Language.Norwegian]: 'Unik ID levert av arbeidsgiveren din for å legge dem til i listen over selskaper',
}

export const ACCOUNT_CREATED = {
  [Language.English]: 'Account created',
  [Language.Estonian]: 'Konto loodud',
  [Language.Norwegian]: 'Konto opprettet',
}

export const SHIFT_STARTED = {
  [Language.English]: 'Shift started',
  [Language.Estonian]: 'Vahetus algas',
  [Language.Norwegian]: 'Skiftet startet',
}

export const SHIFT_ENDED = {
  [Language.English]: 'Shift ended',
  [Language.Estonian]: 'Vahetus lõppes',
  [Language.Norwegian]: 'Skiftet avsluttet',
}

export const YES = {
  [Language.English]: 'Yes',
  [Language.Estonian]: 'Jah',
  [Language.Norwegian]: 'Ja',
}

export const NO = {
  [Language.English]: 'No',
  [Language.Estonian]: 'Ei',
  [Language.Norwegian]: 'Nei',
}

export const ATTENTION = {
  [Language.English]: 'Attention!',
  [Language.Estonian]: 'Tähelepanu!',
  [Language.Norwegian]: 'Merk følgende!',
}

export const EMPLOYEES = {
  [Language.English]: 'Employees',
  [Language.Estonian]: 'Töötajad',
  [Language.Norwegian]: 'Arbeidere',
}

export const COMPANY_NAME = {
  [Language.English]: 'Company Name',
  [Language.Estonian]: 'Firma nimi',
  [Language.Norwegian]: 'Selskapsnavn',
}

export const DELETE_ACCOUNT = {
  [Language.English]: 'Delete Account',
  [Language.Estonian]: 'Kustuta Konto',
  [Language.Norwegian]: 'Slett Konto',
}

export const REGISTER_COMPANY_ACCOUNT = {
  [Language.English]: 'Register as an employer',
  [Language.Estonian]: 'Registreeri tööandjana',
  [Language.Norwegian]: 'Registrer deg som arbeidsgiver',
}

export const DEFAULT_SHIFT_SETTINGS = {
  [Language.English]: 'Default Shift Settings',
  [Language.Estonian]: 'Standard Vahetuse Sätted',
  [Language.Norwegian]: 'Standard Skift-Innstillinger',
}

export const NOT_SPECIFIED = {
  [Language.English]: 'Not Specified',
  [Language.Estonian]: 'Täpsustamata',
  [Language.Norwegian]: 'Ikke Spesifisert',
}

export const NO_EMPLOYEES_TEXT = {
  [Language.English]: 'No employee has added you as their employer. Please go to the bottom of your settings page to get your unique ID to share with your employees so they can add your company on their account.',
  [Language.Estonian]: 'Ükski töötaja pole teid oma tööandjaks lisanud. Seadete lehe allosas on Teie unikaalne ID, mida saate töötajatega jagada, et nad saaks firma oma kontol lisada.',
  [Language.Norwegian]: 'Ingen ansatte har lagt deg til som arbeidsgiver. Gå til bunnen av innstillingssiden for å få din unike ID for å dele med dine ansatte slik at de kan legge til bedriften din på kontoen sin.',
}

export const USER_UPDATED_SUCCESSFULLY = {
  [Language.English]: 'User updated successfully',
  [Language.Estonian]: 'Sätted edukalt värskendatud',
  [Language.Norwegian]: 'Brukeren er oppdatert',
}

export const DEVELOPED_BY = {
  [Language.English]: 'Developed by Vellaware OÜ',
  [Language.Estonian]: 'Vellaware OÜ',
  [Language.Norwegian]: 'Utviklet av Vellaware OÜ',
}

export const TIMETABLES = {
  [Language.English]: 'Timetables',
  [Language.Estonian]: 'Vahetused',
  [Language.Norwegian]: 'Timeregistrering',
}

export const PRIVACY_POLICY_AND_TC = {
  [Language.English]: "Privacy Policy and Terms & Conditions",
  [Language.Estonian]: 'Privaatsuspoliitika ning Tingimused',
  [Language.Norwegian]: 'Personvernregler og Vilkår',
};

export const I_AGREE = {
  [Language.English]: "I Agree to The Privacy Policy and Terms & Conditions",
  [Language.Estonian]: 'Nõustun Privaatsuspoliitika ning Tingimustega',
  [Language.Norwegian]: 'Jeg Godtar Personvernreglene og Vilkårene',
};

export const CONFIRM_LOGOUT = {
  [Language.English]: "Are you sure you wish to log out?",
  [Language.Estonian]: 'Kas olete kindel, et soovite välja logida?',
  [Language.Norwegian]: 'Er du sikker på at du vil logge ut?',
};
