import { useRef, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Row, Button, Form, Col } from "reactstrap"

import usePersist from "../../hooks/usePersist"
import useAuth from "../../hooks/useAuth"

import { setCredentials } from "./auth-slices/authSlice"
import { useLoginMutation } from "./auth-slices/authApiSlice"
import { selectCurrentLanguage } from "../language/languageSlice"

import Swal from "sweetalert2"

import { InputField } from "../../components/InputField"

import { LOGIN, USERNAME_OR_EMAIL, PASSWORD, STAY_LOGGED_IN, DONT_HAVE_AN_ACCOUNT, REGISTER } from "../../assets/texts/texts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons"

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useAuth()
  const [persist, setPersist] = usePersist()
  const errRef = useRef()

  const language = useSelector(selectCurrentLanguage)

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [login, { isLoading }] = useLoginMutation()

  useEffect(() => {
  }, [language])

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const { accessToken } = await login({ username: username?.toLowerCase(), password }).unwrap()
      dispatch(setCredentials({ accessToken }))
      setUsername("")
      setPassword("")
      navigate("/")
    } catch (err) {
      console.log(err)
      if (!err.status) {
        Swal.fire({
          title: 'Error!',
          text: 'No Server Response',
          icon: 'error',
        })
      } else if (err.status === 400) {
        Swal.fire({
          title: 'Error!',
          text: 'Missing Username or Password',
          icon: 'error',
        })
      } else if (err.status === 403) {
        Swal.fire({
          title: 'Error!',
          text: 'Account not verified. Please click the link in your email.',
          icon: 'error',
        })
      } else if (err.status === 401) {
        Swal.fire({
          title: 'Error!',
          text: 'Unauthorized',
          icon: 'error',
        })
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Something went wrong',
          icon: 'error',
        })
      }
      errRef?.current?.focus()
    }
  }

  if (isLoading) return

  if (auth?.username?.length) {
    setTimeout(() => {
      navigate("/")
    }, 50)
  }

  return (
    <>
      <h2>{LOGIN[language]}</h2>
      <Form className="mb-4" onSubmit={(e) => handleSubmit(e)}>
        <InputField 
          id="username"
          label={USERNAME_OR_EMAIL[language]}
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <InputField 
          id="password"
          label={PASSWORD[language]}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Row>
          <Col>
            <label className="mt-2" htmlFor="persist">
              <span className="mr-2">{STAY_LOGGED_IN[language]}</span>
              <FontAwesomeIcon
                name="persist"
                onClick={() => setPersist((prev) => !prev)}
                size="lg"
                icon={persist ? faToggleOn : faToggleOff}
                color={persist ? "rgb(23, 152, 207)" : "grey"}
              />
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button className="mt-2" style={{ backgroundColor: '#08c792', color: 'white' }} type="submit">
              {LOGIN[language]}
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            {DONT_HAVE_AN_ACCOUNT[language]} <u style={{ cursor: "pointer" }} onClick={() => navigate('/register')}>
              {REGISTER[language]}</u>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default Login
