import { Language } from "../../assets/texts/languages.ts"

export const PRIVACY_POLICY_TITLE = {
  [Language.English]: 'Privacy Policy for Vellaware OÜ (17046934)',
  [Language.Estonian]: 'Vellaware OÜ (17046934) Privaatsuspoliitika',
  [Language.Norwegian]: 'Personvernerklæring for Vellaware OÜ (17046934)',
};

export const PP_EFFECTIVE_DATE = {
  [Language.English]: 'Effective Date: 14th of August, 2024',
  [Language.Estonian]: 'Kehtiv alates: 14. august 2024',
  [Language.Norwegian]: 'Ikrafttredelsesdato: 14. august 2024',
};

export const INTRODUCTION = {
  [Language.English]: 'Introduction',
  [Language.Estonian]: 'Sissejuhatus',
  [Language.Norwegian]: 'Introduksjon',
};

export const INTRODUCTION_TEXT = {
  [Language.English]: 'Vellaware OÜ ("we", "us", "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your personal data when you use our services.',
  [Language.Estonian]: 'Vellaware OÜ ("meie", "me") on pühendunud teie privaatsuse kaitsmisele. See privaatsuspoliitika selgitab, kuidas me kogume, kasutame ja kaitseme teie isikuandmeid, kui te meie teenuseid kasutate.',
  [Language.Norwegian]: 'Vellaware OÜ ("vi", "oss", "vår") er forpliktet til å beskytte personvernet ditt. Denne personvernerklæringen forklarer hvordan vi samler inn, bruker og beskytter dine personopplysninger når du bruker tjenestene våre.',
};

export const DATA_WE_COLLECT = {
  [Language.English]: 'Data We Collect',
  [Language.Estonian]: 'Andmed, Mida Kogume',
  [Language.Norwegian]: 'Data Vi Samler Inn',
};

export const PERSONAL_INFORMATION = {
  [Language.English]: 'Personal Information',
  [Language.Estonian]: 'Personaalne Info',
  [Language.Norwegian]: 'Personlig Informasjon',
};

export const PERSONAL_INFORMATION_TEXT = {
  [Language.English]: 'We collect your full name and company name when you register an account.',
  [Language.Estonian]: 'Konto registreerimisel kogume teie täisnime ja/või ettevõtte nime.',
  [Language.Norwegian]: 'Vi samler inn ditt fulle navn og firmanavn når du registrerer en konto.',
};

export const COOKIES = {
  [Language.English]: 'Cookies',
  [Language.Estonian]: 'Küpsised',
  [Language.Norwegian]: 'Informasjonskapsler',
};

export const COOKIES_TEXT = {
  [Language.English]: 'We use cookies to store a refresh token that refreshes your access token every 15 minutes. The refresh token is stored for 30 days or until you log out.',
  [Language.Estonian]: 'Kasutame küpsiseid, et salvestada värskendusluba, mis värskendab teie juurdepääsuluba iga 15 minuti järel. Värskendusluba säilitatakse 30 päeva või kuni väljalogimiseni.',
  [Language.Norwegian]: 'Vi bruker informasjonskapsler for å lagre et oppdateringstoken som oppdaterer tilgangstokenet ditt hvert 15. minutt. Oppdateringstokenet lagres i 30 dager eller til du logger ut.',
};

export const DATA_USAGE = {
  [Language.English]: 'How We Use Your Data',
  [Language.Estonian]: 'Kuidas Teie Andmeid Kasutame',
  [Language.Norwegian]: 'Hvordan Vi Bruker Dataene Dine',
};

export const USER_ACCESS = {
  [Language.English]: 'User Access',
  [Language.Estonian]: 'Kasutaja Juurdepääs',
  [Language.Norwegian]: 'Brukertilgang',
};

export const USER_ACCESS_TEXT = {
  [Language.English]: "Your data is used solely to provide you with access to your logged shifts and for employers to view shifts logged under their company's name.",
  [Language.Estonian]: 'Teie andmeid kasutatakse ainult selleks, et anda teile juurdepääs teie registreeritud vahetustele ja et tööandjad saaksid vaadata oma ettevõtte nime all registreeritud vahetusi.',
  [Language.Norwegian]: 'Dataene dine brukes utelukkende for å gi deg tilgang til dine loggede skift og for at arbeidsgivere skal kunne se skift logget under firmaets navn.',
};

export const SHARING = {
  [Language.English]: 'No Third-Party Sharing',
  [Language.Estonian]: 'Kolmandad Osapooled',
  [Language.Norwegian]: 'Ingen Tredjepartsdeling',
};

export const SHARING_TEXT = {
  [Language.English]: 'We do not share your personal data with any third parties.',
  [Language.Estonian]: 'Me ei jaga teie isikuandmeid ühegi kolmanda osapoolega.',
  [Language.Norwegian]: 'Vi deler ikke dine personopplysninger med noen tredjeparter.',
};

export const DATA_STORAGE = {
  [Language.English]: 'Data Storage and Security',
  [Language.Estonian]: 'Andmete Säilitamine ja Turvalisus',
  [Language.Norwegian]: 'Datalagring og Sikkerhet',
};

export const STORAGE = {
  [Language.English]: 'Storage',
  [Language.Estonian]: 'Säilitamine',
  [Language.Norwegian]: 'Lagring',
};

export const STORAGE_TEXT = {
  [Language.English]: 'Your data is stored in MongoDB, hosted on AWS servers located in Stockholm.',
  [Language.Estonian]: 'Teie andmeid hoitakse MongoDB-s, mis on majutatud Stockholmis asuvates AWS-i serverites.',
  [Language.Norwegian]: 'Dataene dine lagres i MongoDB, hostet på AWS-servere i Stockholm.',
};

export const ACCESS_CONTROL = {
  [Language.English]: 'Access Control',
  [Language.Estonian]: 'Juurdepääsu Kontroll',
  [Language.Norwegian]: 'Tilgangskontroll',
};

export const ACCESS_CONTROL_TEXT = {
  [Language.English]: "You can only access your own data. Company accounts can in addition access shifts logged under the company's, and their employees' names and usernames.",
  [Language.Estonian]: 'Saate juurde pääseda ainult oma andmetele. Ettevõtte kontodel on lisaks juurdepääs ettevõtte ja nende töötajate nimede ja kasutajanimede alla logitud vahetustele.',
  [Language.Norwegian]: 'Du har kun tilgang til dine egne data. Bedriftskontoer kan i tillegg få tilgang til skift logget under bedriftens, og deres ansattes navn og brukernavn.',
};

export const SECURITY = {
  [Language.English]: 'Security Measures',
  [Language.Estonian]: 'Turvameetmed',
  [Language.Norwegian]: 'Sikkerhetstiltak',
};

export const SECURITY_TEXT = {
  [Language.English]: 'We take all necessary precautions to secure your data and prevent unauthorized access.',
  [Language.Estonian]: 'Võtame kõik vajalikud ettevaatusabinõud teie andmete kaitsmiseks ja volitamata juurdepääsu vältimiseks.',
  [Language.Norwegian]: 'Vi tar alle nødvendige forholdsregler for å sikre dataene dine og forhindre uautorisert tilgang.',
};

export const RIGHTS = {
  [Language.English]: 'Your Rights',
  [Language.Estonian]: 'Teie Õigused',
  [Language.Norwegian]: 'Dine Rettigheter',
};

export const ACCESS = {
  [Language.English]: 'Access and Rectification',
  [Language.Estonian]: 'Juurdepääs ja Parandamine',
  [Language.Norwegian]: 'Tilgang og Retting',
};

export const ACCESS_TEXT = {
  [Language.English]: 'You have the right to access and correct your personal data.',
  [Language.Estonian]: 'Teil on õigus oma isikuandmetele juurde pääseda ja neid parandada.',
  [Language.Norwegian]: 'Du har rett til å få tilgang til og korrigere dine personopplysninger.',
};

export const DELETION = {
  [Language.English]: 'Deletion',
  [Language.Estonian]: 'Kustutamine',
  [Language.Norwegian]: 'Sletting',
};

export const DELETION_TEXT = {
  [Language.English]: 'You can delete your account at any time, which will permanently delete all your data, including logged shifts. Shifts can also be deleted separately.',
  [Language.Estonian]: 'Saate oma konto igal ajal kustutada, mis kustutab jäädavalt kõik teie andmed, sealhulgas logitud vahetused. Vahetusi saab ka eraldi kustutada.',
  [Language.Norwegian]: 'Du kan slette kontoen din når som helst, noe som vil slette alle dataene dine permanent, inkludert loggede skift. Skift kan også slettes separat.',
};

export const PORTABILITY = {
  [Language.English]: 'Data Portability',
  [Language.Estonian]: 'Andmete Teisaldatavus',
  [Language.Norwegian]: 'Dataportabilitet',
};

export const PORTABILITY_TEXT = {
  [Language.English]: 'You have the right to request a copy of your data from info@vellaware.com.',
  [Language.Estonian]: 'Teil on õigus nõuda oma andmete koopiat aadressilt info@vellaware.com.',
  [Language.Norwegian]: 'Du har rett til å be om en kopi av dataene dine fra info@vellaware.com.',
};

export const TRANSFERS = {
  [Language.English]: 'International Data Transfers',
  [Language.Estonian]: 'Rahvusvahelised Andmeedastused',
  [Language.Norwegian]: 'Internasjonale Dataoverføringer',
};

export const TRANSFERS_USERS = {
  [Language.English]: 'EU, Iceland, Liechtenstein, Norway, Switzerland and US Users',
  [Language.Estonian]: 'EL, Island, Liechtenstein, Norra, Šveits ja USA kasutajad',
  [Language.Norwegian]: 'Brukere i EU, Island, Liechtenstein, Norge, Sveits og USA',
};

export const TRANSFERS_TEXT = {
  [Language.English]: 'Your data is stored in the EU, and we comply with all relevant data protection regulations.',
  [Language.Estonian]: 'Teie andmeid säilitatakse EL-is ja me järgime kõiki asjakohaseid andmekaitseeeskirju.',
  [Language.Norwegian]: 'Dataene dine lagres i EU, og vi overholder alle relevante databeskyttelsesbestemmelser.',
};

export const COOKIE_POLICY = {
  [Language.English]: 'Cookie Policy',
  [Language.Estonian]: 'Küpsiste Poliitika',
  [Language.Norwegian]: 'Retningslinjer for Informasjonskapsler',
};

export const PURPOSE = {
  [Language.English]: 'Purpose',
  [Language.Estonian]: 'Eesmärk',
  [Language.Norwegian]: 'Hensikt',
};

export const PURPOSE_TEXT = {
  [Language.English]: 'We use cookies to manage session tokens and maintain your logged-in status.',
  [Language.Estonian]: 'Kasutame küpsiseid, et hallata seansimärke ja säilitada teie sisselogimise olek.',
  [Language.Norwegian]: 'Vi bruker informasjonskapsler for å administrere økttokens og opprettholde din påloggede status.',
};

export const DURATION = {
  [Language.English]: 'Duration',
  [Language.Estonian]: 'Kestus',
  [Language.Norwegian]: 'Varighet',
};

export const DURATION_TEXT = {
  [Language.English]: 'Cookies are kept for 30 days or until you log out.',
  [Language.Estonian]: 'Küpsiseid säilitatakse 30 päeva või kuni välja logite.',
  [Language.Norwegian]: 'Informasjonskapsler lagres i 30 dager eller til du logger ut.',
};

export const CONSENT = {
  [Language.English]: 'Consent',
  [Language.Estonian]: 'Nõusolek',
  [Language.Norwegian]: 'Tillatelse',
};

export const CONSENT_TEXT = {
  [Language.English]: 'By using our service, you consent to the use of cookies as described.',
  [Language.Estonian]: 'Meie teenust kasutades nõustute küpsiste kasutamisega kirjeldatud viisil.',
  [Language.Norwegian]: 'Ved å bruke tjenesten vår samtykker du til bruken av informasjonskapsler som beskrevet.',
};

export const TC = {
  [Language.English]: 'Terms & Conditions for Vellaware OÜ (17046934)',
  [Language.Estonian]: 'Vellaware OÜ (17046934) Tingimused',
  [Language.Norwegian]: 'Vilkår og betingelser for Vellaware OÜ (17046934)',
};

export const TC_EFFECTIVE_DATE = {
  [Language.English]: 'Effective Date: 14th of August, 2024',
  [Language.Estonian]: 'Kehtib Alates: 14. August 2024',
  [Language.Norwegian]: 'Ikrafttredelsesdato: 14. August 2024',
};

export const TC_INTRODUCTION_TEXT = {
  [Language.English]: 'These Terms & Conditions ("Terms") govern your use of the Vellaware OÜ services. By using our services, you agree to these Terms.',
  [Language.Estonian]: 'Käesolevad Tingimused ("Tingimused") reguleerivad Vellaware OÜ teenuste kasutamist. Meie teenuseid kasutades nõustute nende tingimustega.',
  [Language.Norwegian]: 'Disse vilkårene og betingelsene ("vilkårene") styrer din bruk av Vellaware OÜ-tjenestene. Ved å bruke våre tjenester godtar du disse vilkårene.',
};

export const OBLIGATIONS = {
  [Language.English]: 'User Obligations',
  [Language.Estonian]: 'Kasutaja Kohustused',
  [Language.Norwegian]: 'Brukerforpliktelser',
};

export const ACC_INFORMATION = {
  [Language.English]: 'Account Information',
  [Language.Estonian]: 'Konto Teave',
  [Language.Norwegian]: 'Kontoinformasjon',
};

export const ACC_INFORMATION_TEXT = {
  [Language.English]: 'You must provide accurate information when creating your account, including your full name or company name.',
  [Language.Estonian]: 'Konto loomisel peate esitama täpse teabe, sealhulgas oma täisnime või ettevõtte nime.',
  [Language.Norwegian]: 'Du må oppgi nøyaktig informasjon når du oppretter kontoen din, inkludert fullt navn eller firmanavn.',
};

export const USE = {
  [Language.English]: 'Proper Use',
  [Language.Estonian]: 'Õige Kasutamine',
  [Language.Norwegian]: 'Riktig Bruk',
};

export const USE_TEXT = {
  [Language.English]: 'You agree not to use inappropriate descriptions when logging shifts and not to use an inappropriate name or username when registering or updating your account.',
  [Language.Estonian]: 'Nõustute mitte kasutama vahetuste logimisel sobimatuid kirjeldusi ja mitte kasutama oma konto registreerimisel või värskendamisel sobimatut nime ega kasutajanime.',
  [Language.Norwegian]: 'Du samtykker i å ikke bruke upassende beskrivelser når du logger skift og ikke bruke et upassende navn eller brukernavn når du registrerer eller oppdaterer kontoen din.',
};

export const MANAGEMENT = {
  [Language.English]: 'Account Management',
  [Language.Estonian]: 'Kontohaldus',
  [Language.Norwegian]: 'Kontoadministrasjon',
};

export const CREATION = {
  [Language.English]: 'Account Creation',
  [Language.Estonian]: 'Konto Loomine',
  [Language.Norwegian]: 'Kontoopprettelse',
};

export const CREATION_TEXT = {
  [Language.English]: 'You are responsible for maintaining the confidentiality of your login credentials.',
  [Language.Estonian]: 'Teie vastutate oma sisselogimismandaatide konfidentsiaalsuse säilitamise eest.',
  [Language.Norwegian]: 'Du er ansvarlig for å opprettholde konfidensialiteten til påloggingsinformasjonen din.',
};

export const ACC_DELETION = {
  [Language.English]: 'Account Deletion',
  [Language.Estonian]: 'Konto Kustutamine',
  [Language.Norwegian]: 'Sletting av Konto',
};

export const ACC_DELETION_TEXT = {
  [Language.English]: 'You can delete your account at any time, which will also delete all associated data instantly.',
  [Language.Estonian]: 'Saate oma konto igal ajal kustutada, mis kustutab koheselt ka kõik seotud andmed.',
  [Language.Norwegian]: 'Du kan slette kontoen din når som helst, noe som også vil slette alle tilknyttede data umiddelbart.',
};

export const DATA_ACCESS = {
  [Language.English]: 'Data Use and Access',
  [Language.Estonian]: 'Andmete Kasutamine ja Juurdepääs',
  [Language.Norwegian]: 'Databruk og Tilgang',
};

export const PERSONAL_DATA = {
  [Language.English]: 'Personal Data',
  [Language.Estonian]: 'Isikuandmed',
  [Language.Norwegian]: 'Personlige Data',
};

export const PERSONAL_DATA_TEXT = {
  [Language.English]: "Your data is used solely for providing you with access to your logged shifts and for employers to view shifts logged under their company's name, including the name and username of users that have added the company to their account.",
  [Language.Estonian]: 'Teie andmeid kasutatakse ainult selleks, et anda teile juurdepääs teie registreeritud vahetustele ja et tööandjad saaksid vaadata oma ettevõtte nime all logitud vahetusi, sealhulgas teie nime ja kasutajanime juhul kui olete firma oma kontole lisanud.',
  [Language.Norwegian]: 'Dataene dine brukes utelukkende for å gi deg tilgang til dine loggede skift og for at arbeidsgivere skal kunne se skift logget under firmaets navn, inkludert navn og brukernavn til brukere som har lagt til bedriften på kontoen sin.',
};

export const COMPANY_ACCESS = {
  [Language.English]: 'Company Access',
  [Language.Estonian]: 'Ettevõtte Juurdepääs',
  [Language.Norwegian]: 'Bedriftstilgang',
};

export const COMPANY_ACCESS_TEXT = {
  [Language.English]: "Employers can only access shifts logged under their company name, and the names and usernames of users that have added said company to their account.",
  [Language.Estonian]: 'Tööandjad pääsevad juurde ainult nende ettevõtte nime all logitud vahetustele ning nende kasutajate nimedele ja kasutajanimedele, kes on selle ettevõtte oma kontole lisanud.',
  [Language.Norwegian]: 'Arbeidsgivere kan kun få tilgang til skift logget under firmanavnet deres, og navnene og brukernavnene til brukere som har lagt til firmaet på kontoen sin.',
};

export const DISCLAIMER = {
  [Language.English]: 'Liability Disclaimer',
  [Language.Estonian]: 'Vastutusest Loobumine',
  [Language.Norwegian]: 'Ansvarsfraskrivelse',
};

export const AVAILABILITY = {
  [Language.English]: 'Service Availability',
  [Language.Estonian]: 'Teenuse Kättesaadavus',
  [Language.Norwegian]: 'Tjenestetilgjengelighet',
};

export const AVAILABILITY_TEXT = {
  [Language.English]: 'We strive to maintain the availability of our services but do not guarantee uninterrupted access.',
  [Language.Estonian]: 'Püüame säilitada oma teenuste kättesaadavuse, kuid ei garanteeri katkematut juurdepääsu.',
  [Language.Norwegian]: 'Vi streber etter å opprettholde tilgjengeligheten til tjenestene våre, men garanterer ikke uavbrutt tilgang.',
};

export const DATA_SECURITY = {
  [Language.English]: 'Data Security',
  [Language.Estonian]: 'Andmeturve',
  [Language.Norwegian]: 'Datasikkerhet',
};

export const DATA_SECURITY_TEXT = {
  [Language.English]: 'We take measures to secure your data but cannot guarantee complete security. We are not liable for any unauthorized access or data breaches.',
  [Language.Estonian]: 'Me rakendame meetmeid teie andmete kaitsmiseks, kuid ei saa tagada täielikku turvalisust. Me ei vastuta volitamata juurdepääsu või andmetega seotud rikkumiste eest.',
  [Language.Norwegian]: 'Vi tar tiltak for å sikre dataene dine, men kan ikke garantere fullstendig sikkerhet. Vi er ikke ansvarlige for uautorisert tilgang eller datainnbrudd.',
};

export const LAW = {
  [Language.English]: 'Governing Law',
  [Language.Estonian]: 'Kehtiv Seadus',
  [Language.Norwegian]: 'Gjeldende lov',
};

export const JURISDICTION = {
  [Language.English]: 'Jurisdiction',
  [Language.Estonian]: 'Jurisdiktsioon',
  [Language.Norwegian]: 'Jurisdiksjon',
};

export const JURISDICTION_TEXT = {
  [Language.English]: 'These Terms are governed by the laws of the Republic of Estonia and the EU.',
  [Language.Estonian]: 'Käesolevaid tingimusi reguleerivad Eesti Vabariigi ja EL seadused.',
  [Language.Norwegian]: 'Disse vilkårene er underlagt lovene i Republikken Estland og EU.',
};

export const CHANGES = {
  [Language.English]: 'Changes to Terms',
  [Language.Estonian]: 'Tingimuste Muudatused',
  [Language.Norwegian]: 'Endringer i Vilkårene',
};

export const NOTIFICATION = {
  [Language.English]: 'Notification',
  [Language.Estonian]: 'Teavitus',
  [Language.Norwegian]: 'Melding',
};

export const NOTIFICATION_TEXT = {
  [Language.English]: 'We may update these Terms from time to time. You will be notified of any changes through a notification on the app that stays up for 7 days.',
  [Language.Estonian]: 'Võime neid tingimusi aeg-ajalt värskendada. Teid teavitatakse kõigist muudatustest rakenduses oleva teatise kaudu, mis jääb üles 7 päevaks.',
  [Language.Norwegian]: 'Vi kan oppdatere disse vilkårene fra tid til annen. Du vil bli varslet om eventuelle endringer gjennom et varsel på appen som holder seg oppe i 7 dager.',
};

export const CONTACT = {
  [Language.English]: 'Contact Information',
  [Language.Estonian]: 'Kontaktandmed',
  [Language.Norwegian]: 'Kontaktinformasjon',
};

export const CONTACT_TEXT = {
  [Language.English]: 'If you have any questions about these Terms or our Privacy Policy, please contact us at info@vellaware.com.',
  [Language.Estonian]: 'Kui teil on nende tingimuste või meie privaatsuspoliitika kohta küsimusi, võtke meiega ühendust aadressil info@vellaware.com.',
  [Language.Norwegian]: 'Hvis du har spørsmål om disse vilkårene eller personvernreglene våre, vennligst kontakt oss på info@vellaware.com.',
};
