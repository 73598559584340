import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useStopwatch } from 'react-timer-hook'
import { Button, Col, Row } from 'reactstrap'

import useAuth from '../../../hooks/useAuth'

import { selectCurrentLanguage } from '../../language/languageSlice'

import { useUpdateUserMutation } from '../../users/user-slices/usersApiSlice'
import { useRefreshMutation } from '../../auth/auth-slices/authApiSlice'

import {
  AMOUNT_OF_HOURS,
  AMOUNT_OF_MINUTES,
  ATTENTION,
  END_SHIFT,
  END_TIMER_TEXT,
  START_SHIFT,
  TIMER_RUNNING
} from '../../../assets/texts/texts'

import { FINISH_SHIFT_WARNING } from '../../../assets/texts/alertTexts'

import { alerts } from '../../utils/alerts'
import { translateError } from '../../utils/translateErrorCodes'

const TimerShift = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const language = useSelector(selectCurrentLanguage)

  const [timerRunning, setTimerRunning] = useState(auth?.timerRunning === true ? true : false)
  const [timestamp, setTimestamp] = useState(auth?.timerBeganTimestamp)

  const currentTime = new Date().getTime()

  const startTime = timerRunning && timestamp !== 0
    ? new Date(currentTime - timestamp + currentTime)
    : new Date()

  const {
    minutes,
    hours,
    days,
    start,
    reset,
    pause
  } = useStopwatch({ autoStart: timerRunning, offsetTimestamp: startTime })

  const [updateUser, { isLoading, isSuccess, isError, error }] =
    useUpdateUserMutation()

  const [refresh, { isLoading: isRefreshLoading }] = useRefreshMutation()

  useEffect(() => {
  }, [language])

  useEffect(() => {
    if (isSuccess) {
      if (timerRunning) {
        setTimestamp(0)
        setTimerRunning(false)
        refresh()
        reset()
        pause()
      } else {
        setTimestamp(new Date())
        setTimerRunning(true)
        refresh()
        start()
      }
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) alerts.errorAlert(translateError({ language, code: error?.data?.code }))
  }, [isError])

  if (auth?.isUser === false) {
    return <Navigate to="/companyusers" state={{ from: location }} replace />
  }

  const startTimer = async () => {
    await updateUser({ id: auth?.userId, timerRunning: true, timerBeganTimestamp: new Date().getTime() })
    setTimerRunning(true)
  }

  const endTimer = async () => {
    const timerStartDate = new Date(timestamp)
    await updateUser({ id: auth?.userId, timerRunning: false, timerBeganTimestamp: 0 })
    setTimestamp(0)
    setTimerRunning(false)
    navigate('/new-shift', { state: { timerStartDate, timerEndDate: new Date() } })
    alerts.warningAlert(FINISH_SHIFT_WARNING[language], ATTENTION[language])
  }

  if (isLoading || isRefreshLoading) return

  return (
    <>
      <Row>
        <Col className='text-center mb-4'>
          <h2>{hours + days * 24} {AMOUNT_OF_HOURS[language]}, {minutes} {AMOUNT_OF_MINUTES[language]}</h2>
        </Col>
      </Row>
      {!timerRunning ? null : 
        <>
          <Row>
            <Col className="text-center">
              <h3 style={{ color: 'green' }}>
                {TIMER_RUNNING[language]}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <h3>
                *{END_TIMER_TEXT[language]}*
              </h3>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                style={{ backgroundColor: '#fb6340', color: 'white' }}
                onClick={() => endTimer()}
              >
                {END_SHIFT[language]}
              </Button>
            </Col>
          </Row>
        </>
      }
      {timerRunning ? null :
        <>
          <Row>
            <Col className="text-center">
              <Button
                style={{ backgroundColor: '#08c792', color: 'white' }}
                onClick={() => startTimer()}
              >
                {START_SHIFT[language]}
              </Button>
            </Col>
          </Row>
        </>
      }
    </>
  )
}

export default TimerShift
