import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Col, Row } from "reactstrap"

import { selectCurrentLanguage } from "../language/languageSlice"

const Notification = ({ notification }) => {
  const language = useSelector(selectCurrentLanguage)

  useEffect(() => {
  }, [language])

  const text = notification?.[`${language.toLowerCase()}Text`] || notification?.englishText || null;

  if (text?.length) {
    return (
      <Row style={{ backgroundColor: 'red' }}>
        <Col className="text-center">
          <b style={{ color: 'white' }}>
            {text}
          </b>
        </Col>
      </Row>
    )
  }

  return
}

export default Notification
