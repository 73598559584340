import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"

import { apiSlice } from "../../../app/api/apiSlice"

const companiesAdapter = createEntityAdapter({})

const initialState = companiesAdapter.getInitialState()

export const companiesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUserCompanies: builder.query({
            query: (userId) => ({
                url: `/companies/${userId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedCompanies = responseData.map(company => {
                    company.id = company._id
                    return company
                })
                return companiesAdapter.setAll(initialState, loadedCompanies)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Company', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Company', id }))
                    ]
                } else return [{ type: 'Company', id: 'LIST' }]
            }
        }),
        addNewCompany: builder.mutation({
            query: initialCompanyData => ({
                url: '/companies',
                method: 'POST',
                body: {
                    ...initialCompanyData,
                }
            }),
            invalidatesTags: [
                { type: 'Company', id: "LIST" }
            ]
        }),
        updateCompany: builder.mutation({
            query: initalCompanyData => ({
                url: '/companies',
                method: 'PATCH',
                body: {
                    ...initalCompanyData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Company', id: arg.id }
            ]
        }),
        deleteCompany: builder.mutation({
            query: initialCompanyData => ({
                url: '/companies',
                method: 'DELETE',
                body: { ...initialCompanyData }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Company', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetUserCompaniesQuery,
    useAddNewCompanyMutation,
    useUpdateCompanyMutation,
    useDeleteCompanyMutation,
} = companiesApiSlice

export const selectCompaniesResult = companiesApiSlice.endpoints.getUserCompanies.select()

const selectCompaniesData = createSelector(
    selectCompaniesResult,
    companiesResult => companiesResult.data
)

export const {
    selectAll: selectAllCompanies,
    selectById: selectCompanyById,
    selectIds: selectCompaniesIds
} = companiesAdapter.getSelectors(state => selectCompaniesData(state) ?? initialState)
