import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials } from '../../features/auth/auth-slices/authSlice'

const domainPieces = process.env.REACT_APP_NODE_ENV !== "production" ? null : window.location.hostname.split('.')
const domainEnd = process.env.REACT_APP_NODE_ENV !== "production" ? 'ee' : domainPieces[domainPieces.length - 1]

const backendUrl = domainEnd === 'ee'
  ? process.env.REACT_APP_ESTONIAN_BACKEND_URL
  : process.env.REACT_APP_BACKEND_URL

const baseQuery = fetchBaseQuery({
  baseUrl: `${backendUrl}`,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token

    if (token) {
      headers.set("authorization", `Bearer ${token}`)
    }
    return headers
  }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error?.status === 403) {
    console.log('sending refresh token')
    const refreshResult = await baseQuery('/auth/refresh', api, extraOptions)

    if (refreshResult?.data) {
      api.dispatch(setCredentials({ ...refreshResult.data }))
      result = await baseQuery(args, api, extraOptions)
    } else {
      if (refreshResult?.error?.status === 403) {
        refreshResult.error.data.message = "Your login has expired."
      }
      return refreshResult
    }
  }

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User', 'Shift', 'Company', 'Notification'],
  endpoints: builder => ({})
})
