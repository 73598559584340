import {
    DECEMBER,
    FEBRUARY,
    JANUARY,
    MARCH,
    NOVEMBER,
    OCTOBER,
    SEPTEMBER,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST
} from "../../assets/texts/texts"

export const currencyOptions = [
    { value: 'EUR', label: 'EUR' },
    { value: 'NOK', label: 'NOK' },
    { value: 'GBP', label: 'GBP' },
    { value: 'USD', label: 'USD' }
]

export const months = [JANUARY, FEBRUARY, MARCH, APRIL, MAY, JUNE, JULY, AUGUST, SEPTEMBER, OCTOBER, NOVEMBER, DECEMBER]
