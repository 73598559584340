import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'

import { selectCurrentLanguage } from '../features/language/languageSlice'

import {
  ACC_DELETION,
  ACC_DELETION_TEXT,
  ACC_INFORMATION,
  ACC_INFORMATION_TEXT,
  ACCESS,
  ACCESS_CONTROL,
  ACCESS_CONTROL_TEXT,
  ACCESS_TEXT,
  AVAILABILITY,
  AVAILABILITY_TEXT,
  CHANGES,
  COMPANY_ACCESS,
  COMPANY_ACCESS_TEXT,
  CONSENT,
  CONSENT_TEXT,
  CONTACT,
  CONTACT_TEXT,
  COOKIE_POLICY,
  COOKIES,
  COOKIES_TEXT,
  CREATION,
  CREATION_TEXT,
  DATA_ACCESS,
  DATA_SECURITY,
  DATA_SECURITY_TEXT,
  DATA_STORAGE,
  DATA_USAGE,
  DATA_WE_COLLECT,
  DELETION,
  DELETION_TEXT,
  DISCLAIMER,
  DURATION,
  DURATION_TEXT,
  INTRODUCTION,
  INTRODUCTION_TEXT,
  JURISDICTION,
  JURISDICTION_TEXT,
  LAW,
  MANAGEMENT,
  NOTIFICATION,
  NOTIFICATION_TEXT,
  OBLIGATIONS,
  PERSONAL_DATA,
  PERSONAL_DATA_TEXT,
  PERSONAL_INFORMATION,
  PERSONAL_INFORMATION_TEXT,
  PORTABILITY,
  PORTABILITY_TEXT,
  PP_EFFECTIVE_DATE,
  PRIVACY_POLICY_TITLE,
  PURPOSE,
  PURPOSE_TEXT,
  RIGHTS,
  SECURITY,
  SECURITY_TEXT,
  SHARING,
  SHARING_TEXT,
  STORAGE,
  STORAGE_TEXT,
  TC,
  TC_EFFECTIVE_DATE,
  TC_INTRODUCTION_TEXT,
  TRANSFERS,
  TRANSFERS_TEXT,
  TRANSFERS_USERS,
  USE,
  USE_TEXT,
  USER_ACCESS,
  USER_ACCESS_TEXT
} from './texts/policyTexts'

const TermsConditions = () => {
  const language = useSelector(selectCurrentLanguage)

  useEffect(() => {
  }, [language])

  return (
    <>
      <Row>
        <Col>
          <h2>
            {PRIVACY_POLICY_TITLE[language]}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {PP_EFFECTIVE_DATE[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            1. {INTRODUCTION[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {INTRODUCTION_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            2. {DATA_WE_COLLECT[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{PERSONAL_INFORMATION[language]}:</b> {PERSONAL_INFORMATION_TEXT[language]}  
        </Col>
      </Row>
      <Row>
        <Col>       
          <b>{COOKIES[language]}:</b> {COOKIES_TEXT[language]}       
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            3. {DATA_USAGE[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{USER_ACCESS[language]}:</b> {USER_ACCESS_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{SHARING[language]}:</b> {SHARING_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            4. {DATA_STORAGE[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{STORAGE[language]}:</b> {STORAGE_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{ACCESS_CONTROL[language]}:</b> {ACCESS_CONTROL_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{SECURITY[language]}:</b> {SECURITY_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            5. {RIGHTS[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{ACCESS[language]}:</b> {ACCESS_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{DELETION[language]}:</b> {DELETION_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{PORTABILITY[language]}:</b> {PORTABILITY_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            6. {TRANSFERS[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{TRANSFERS_USERS[language]}:</b> {TRANSFERS_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            7. {COOKIE_POLICY[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{PURPOSE[language]}:</b> {PURPOSE_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{DURATION[language]}:</b> {DURATION_TEXT[language]}
        </Col>
      </Row>
      <Row className='mb-6'>
        <Col>
          <b>{CONSENT[language]}:</b> {CONSENT_TEXT[language]}
        </Col>
      </Row>
      <hr />
      <Row className='mt-6'>
        <Col>
          <h2>
            {TC[language]}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          {TC_EFFECTIVE_DATE[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            1. {INTRODUCTION[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          {TC_INTRODUCTION_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            2. {OBLIGATIONS[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{ACC_INFORMATION[language]}:</b> {ACC_INFORMATION_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{USE[language]}:</b> {USE_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            3. {MANAGEMENT[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{CREATION[language]}:</b> {CREATION_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{ACC_DELETION[language]}:</b> {ACC_DELETION_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            4. {DATA_ACCESS[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{PERSONAL_DATA[language]}:</b> {PERSONAL_DATA_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{COMPANY_ACCESS[language]}:</b> {COMPANY_ACCESS_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            5. {DISCLAIMER[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{AVAILABILITY[language]}:</b> {AVAILABILITY_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{DATA_SECURITY[language]}:</b> {DATA_SECURITY_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            6. {LAW[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{JURISDICTION[language]}:</b> {JURISDICTION_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            7. {CHANGES[language]}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <b>{NOTIFICATION[language]}:</b> {NOTIFICATION_TEXT[language]}
        </Col>
      </Row>
      <Row>
        <Col>
          <h3 className="mt-4">
            8. {CONTACT[language]}
          </h3>
        </Col>
      </Row>
      <Row className="mb-6">
        <Col>
          {CONTACT_TEXT[language]}
        </Col>
      </Row>
    </>
  )
}

export default TermsConditions
