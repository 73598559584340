import { useLocation, Navigate, Outlet } from "react-router-dom"

import useAuth from "../../hooks/useAuth"

const RequireCompanyAuth = () => {
  const { isUser } = useAuth()
  const location = useLocation()

  return isUser ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Outlet />    
  )
}

export default RequireCompanyAuth
