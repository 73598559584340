import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'
import Select from "react-select"

import axios from 'axios'

import useAuth from '../../hooks/useAuth'

import CompanyUser from './CompanyUser'

import { InputField } from '../../components/InputField'

import { selectCurrentToken } from '../auth/auth-slices/authSlice'
import { selectCurrentLanguage } from '../language/languageSlice'

import { useGetCompanyUsersQuery } from './user-slices/companyUsersApiSlice'

import { months } from '../consts/consts'

import { NUMBER_INPUT_REGEX } from '../utils/regex'
import { generateSummaryExcel, generateSummaryPDF, generateYearOptions, getWeekNumber } from '../utils/utils'

import {
  CLOSE,
  DOWNLOAD_SUMMARY,
  EMPLOYEES,
  EXCEL_FIELDS_EXPLAINED,
  EXCEL_GUIDE,
  MONTH,
  NAME_EXPLANATION,
  NO_EMPLOYEES_TEXT,
  PDF_WEEK,
  TOTAL_HOURS_EXPLANATION,
  TOTAL_LUNCH_EXPLANATION,
  TOTAL_NS_EXPLANATION,
  TOTAL_OT_EXPLANATION,
  TOTAL_SALARY_EXPLANATION,
  USERNAME_EXPLANATION,
  WEEK,
  WEEKLY_SHIFTS,
  YEAR
} from '../../assets/texts/texts'

import { alerts } from '../utils/alerts'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import { translateError } from '../utils/translateErrorCodes'

const CompanyUsersList = () => {
  const auth = useAuth()
  const language = useSelector(selectCurrentLanguage)
  const token = useSelector(selectCurrentToken)

  const [weeklyShifts, setWeeklyShifts] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [year, setYear] = useState(new Date().getFullYear())
  const [month, setMonth] = useState(new Date().getMonth())
  const [week, setWeek] = useState(getWeekNumber(new Date()))

  const timeframe = weeklyShifts ? `${PDF_WEEK[language]}-${week}` : months[month][language]

  useEffect(() => {
  }, [language])

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch
  } = useGetCompanyUsersQuery(auth?.userId, {
    pollingInterval: 600000,
    refetchOnFocus: false,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (isError) alerts.errorAlert(translateError({ language, code: error?.data?.code }))
  }, [isError])

  const downloadFile = async (isExcel) => {
    try {
      const period = weeklyShifts ? 'weeksummary' : 'monthsummary'

      const domainPieces = process.env.REACT_APP_NODE_ENV !== "production" ? null : window.location.hostname.split('.')
      const domainEnd = process.env.REACT_APP_NODE_ENV !== "production" ? 'ee' : domainPieces[domainPieces.length - 1]

      const backendUrl = domainEnd === 'ee'
        ? process.env.REACT_APP_ESTONIAN_BACKEND_URL
        : process.env.REACT_APP_BACKEND_URL

      const periodNumber = weeklyShifts ? week : month

      const response = await axios.get(
        `${backendUrl}/companies/${period}/${auth?.userId}/${year}/${periodNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const data = response.data

      const link = document.createElement('a')

      if (isExcel) {
        const excelData = generateSummaryExcel(data)
        link.href = URL.createObjectURL(excelData)
        link.download = `${auth?.name?.split(' ').join('-')}-${timeframe}-${year}.xlsx`
      } else {
        const pdfBytes = await generateSummaryPDF(
          data,
          !weeklyShifts,
          auth?.name,
          weeklyShifts ? week : month,
          year,
          language
        )

        const blob = new Blob([pdfBytes], { type: 'application/pdf' })
        link.href = URL.createObjectURL(blob)
        link.download = `${auth?.name?.split(' ').join('-')}-${timeframe}-${year}.pdf`
      }

      link.click()
    } catch (error) {
      alerts.errorAlert(`${error}`)
    }
  }

  if (isLoading) return

  if (isSuccess && !isLoading) {
    const userIds = users?.ids
    const userEntities = users?.entities

    const usersList = userIds?.map((id) => userEntities[id])

    return (
      <>
        <Modal
          isOpen={modalOpened}
          toggle={() => {
            setModalOpened((prev) => !prev)
          }}
          keyboard={true}
          backdrop={true}
        >
          <div style={{ position: "relative", right: "2vw", top: "2vh" }}>
            <button
              style={{ cursor: "pointer", width: "40px", height: "40px" }}
              className="close"
              onClick={() => {
                setModalOpened((prev) => !prev)
              }}
              type="button"
            >
              &times;
            </button>
          </div>
          <ModalBody>
            <Row>
              <Col>
                <h3>{EXCEL_FIELDS_EXPLAINED[language]}</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <b>name:</b> {NAME_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>username:</b> {USERNAME_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>totalHours:</b> {TOTAL_HOURS_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>totalLunch:</b> {TOTAL_LUNCH_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>totalNightShiftHours:</b> {TOTAL_NS_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>totalOverTimeHours:</b> {TOTAL_OT_EXPLANATION[language]}
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <b>totalSalary:</b> {TOTAL_SALARY_EXPLANATION[language]}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col className='text-right'>
                <Button
                  onClick={() => setModalOpened((prev) => !prev)}
                >
                  {CLOSE[language]}
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        <Row>
          <Col>
            <h2>
              {DOWNLOAD_SUMMARY[language]}
            </h2>
          </Col>
          <Col className="text-right">
            <label onClick={() => setWeeklyShifts((prev) => !prev)} className="form-control-label" htmlFor="weekly-shifts">
              <span className="mr-2">{WEEKLY_SHIFTS[language]}</span>
              <FontAwesomeIcon
                name="weekly-shifts"
                size="xl"
                icon={weeklyShifts ? faToggleOn : faToggleOff}
                color={weeklyShifts ? "rgb(23, 152, 207)" : "grey"}
              />
            </label>
          </Col>
        </Row>
        <Row>
          <Col>
          {!weeklyShifts 
            ? <>
              <label className="form-control-label" htmlFor="select-month">
                {MONTH[language]}
              </label>
              <Select
                className="mb-4"
                id="select-month"
                name="select-month"
                options={[
                  { value: 0, label: months[0][language] },
                  { value: 1, label: months[1][language] },
                  { value: 2, label: months[2][language] },
                  { value: 3, label: months[3][language] },
                  { value: 4, label: months[4][language] },
                  { value: 5, label: months[5][language] },
                  { value: 6, label: months[6][language] },
                  { value: 7, label: months[7][language] },
                  { value: 8, label: months[8][language] },
                  { value: 9, label: months[9][language] },
                  { value: 10, label: months[10][language] },
                  { value: 11, label: months[11][language] }
                ]}
                  value={{ value: month, label: months[month][language] }}
                  onChange={(option) => setMonth(option.value)}
                />
              </>
            : <>
              <InputField
                id="week"
                name="week"
                style={{ height: "38px" }}
                label={WEEK[language]}
                type="text"
                value={week}
                onChange={(e) => {
                  const value = e.target.value
            
                  if (NUMBER_INPUT_REGEX.test(value)) {
                    if (value === "") setWeek(value)
                    else setWeek(parseInt(value))
                  }
                }}
              />
            </>
          }
          </Col>
          <Col>
            <label className="form-control-label" htmlFor="select-year">
              {YEAR[language]}
            </label>
            <Select
              className="mb-4"
              id="select-year"
              name="select-year"
              options={generateYearOptions()}
              value={{ value: year, label: year }}
              onChange={(option) => setYear(option.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              style={{ backgroundColor: '#0099da', color: 'white' }}
              type='button'
              onClick={() => setModalOpened((prev) => !prev)}
            >
              {EXCEL_GUIDE[language]}
            </Button>
          </Col>
          <Col className='text-right'>
            <Button
              style={{ backgroundColor: '#08c792', color: 'white' }}
              type='button'
              onClick={() => downloadFile(true)}
            >
              XLSX
            </Button>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col className='text-right'>
            <Button
              style={{ backgroundColor: '#08c792', color: 'white' }}
              type='button'
              onClick={() => downloadFile(false)}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col>
            <h2>
              {EMPLOYEES[language]}
            </h2>
          </Col>
        </Row>
        {usersList?.length ? null :
          <Row>
            <Col>
              {NO_EMPLOYEES_TEXT[language]}
            </Col>
          </Row>
        }
        {!usersList?.length ? null :
          usersList?.map((user) => (
            <CompanyUser key={user?.id} user={user} language={language} refetchUsers={refetch} />
          ))
        }
      </>
    )
  }

  return
}

export default CompanyUsersList
