import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"

import { apiSlice } from "./api/apiSlice"
import authReducer from '../features/auth/auth-slices/authSlice'
import languageReducer from '../features/language/languageSlice'
import { companyUserReducer } from "../features/users/user-slices/companyUsersApiSlice"

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    language: languageReducer,
    companyUser: companyUserReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: false
})

setupListeners(store.dispatch)
