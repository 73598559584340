import {
  ALL_FIELDS_REQUIRED,
  COMPANY_ALREADY_IN_LIST,
  COMPANY_NOT_IN_LIST,
  DESCRIPTION_TOO_LONG,
  DUPLICATE_EMAIL,
  DUPLICATE_NAME,
  DUPLICATE_USERNAME,
  FORBIDDEN,
  INVALID_CURRENCY,
  INVALID_DAY,
  INVALID_DEFAULT_LUNCH_HOURS,
  INVALID_EMAIL,
  INVALID_HOURLY_RATE,
  INVALID_LUNCH_HOURS,
  INVALID_MONTH,
  INVALID_MULTIPLIER_PERCENTAGE,
  INVALID_NAME,
  INVALID_NORMAL_HOURS,
  INVALID_PASSWORD,
  INVALID_TIME,
  INVALID_USERNAME,
  INVALID_WEEK,
  INVALID_WORK_HOURS,
  INVALID_YEAR,
  OBJECT_NOT_FOUND,
  SERVER_ERROR,
  UNAUTHORIZED,
  UNDEFINED_ERROR
} from "../../assets/texts/alertTexts"

export const translateError = ({ code, language }) => {
  if (!code) return UNDEFINED_ERROR[language]

  if (code === 11) return ALL_FIELDS_REQUIRED[language]
  if (code === 12) return UNAUTHORIZED[language]
  if (code === 14) return FORBIDDEN[language]

  if (code === 404) return OBJECT_NOT_FOUND[language]
  if (code === 500) return SERVER_ERROR[language]

  if (code === 101) return INVALID_USERNAME[language]
  if (code === 102) return INVALID_NAME[language]
  if (code === 103) return INVALID_EMAIL[language]
  if (code === 107) return INVALID_PASSWORD[language]

  if (code === 104) return DUPLICATE_NAME[language]
  if (code === 105) return DUPLICATE_USERNAME[language]
  if (code === 106) return DUPLICATE_EMAIL[language]

  if (code === 301) return INVALID_YEAR[language]
  if (code === 302) return INVALID_MONTH[language]
  if (code === 303) return INVALID_DAY[language]
  if (code === 304) return INVALID_WEEK[language]
  if (code === 305) return INVALID_TIME[language]
  if (code === 306) return INVALID_WORK_HOURS[language]
  if (code === 307) return INVALID_LUNCH_HOURS[language]
  if (code === 308) return INVALID_NORMAL_HOURS[language]
  if (code === 309) return INVALID_HOURLY_RATE[language]
  if (code === 310) return INVALID_MULTIPLIER_PERCENTAGE[language]
  if (code === 311) return INVALID_MULTIPLIER_PERCENTAGE[language]
  if (code === 312) return DESCRIPTION_TOO_LONG[language]
  if (code === 313) return INVALID_CURRENCY[language]

  if (code === 601) return INVALID_DEFAULT_LUNCH_HOURS[language]
  if (code === 602) return COMPANY_ALREADY_IN_LIST[language]
  if (code === 603) return COMPANY_NOT_IN_LIST[language]

  return UNDEFINED_ERROR[language]
}
