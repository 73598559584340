import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../../app/api/apiSlice";

const usersAdapter = createEntityAdapter({})

const initialState = usersAdapter.getInitialState()

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        addNewUser: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'POST',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: [
                { type: 'User', id: "LIST" }
            ]
        }),
        updateUser: builder.mutation({
            query: initalUserData => ({
                url: '/users',
                method: 'PATCH',
                body: {
                    ...initalUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        removeEmployee: builder.mutation({
            query: initalUserData => ({
                url: '/users/companyremoveuser',
                method: 'PATCH',
                body: {
                    ...initalUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
        deleteUser: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'DELETE',
                body: { ...initialUserData }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),
    }),
})

export const {
    useAddNewUserMutation,
    useUpdateUserMutation,
    useRemoveEmployeeMutation,
    useDeleteUserMutation,
} = usersApiSlice
