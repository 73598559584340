import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Outlet, useNavigate } from "react-router-dom"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"

import useAuth from "../hooks/useAuth"

import Swal from "sweetalert2"

import Header from "./Header"
import NotificationsList from "../features/notifications/NotificationsList"

import { useSendLogoutMutation } from "../features/auth/auth-slices/authApiSlice"
import { selectCurrentLanguage } from "../features/language/languageSlice"
import { alerts } from "../features/utils/alerts"

import { ATTENTION, DEVELOPED_BY, NO, TIMETABLES, YES, CONFIRM_LOGOUT } from "../assets/texts/texts"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
    faCalendarDays,
    faCalendarPlus,
    faGear,
    faRightFromBracket,
    faStopwatch,
} from "@fortawesome/free-solid-svg-icons"
import { translateError } from "../features/utils/translateErrorCodes"

const Layout = () => {
    const auth = useAuth()
    const navigate = useNavigate()
    const language = useSelector(selectCurrentLanguage)

    const domainPieces = process.env.REACT_APP_NODE_ENV !== "production" ? null : window.location.hostname.split('.')
    const domainEnd = process.env.REACT_APP_NODE_ENV !== "production" ? 'ee' : domainPieces[domainPieces.length - 1]

    const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation()

    useEffect(() => {
    }, [language])

    useEffect(() => {
        if (isSuccess) navigate("/")
    }, [isSuccess, navigate])

    useEffect(() => {
        if (isError) {alerts.errorAlert(translateError({ language, code: error?.data?.code }))}
    }, [isError])

    if (isLoading) return

    return <>
        <NotificationsList />
        <Header />
        <Row>
            <Col />
            <Col md="7">
                <Card className="mt--6 mb-4">
                    <CardHeader>
                        <Row>
                            <Col>
                                <span
                                    onClick={() => navigate("/")}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {TIMETABLES[language]}
                                </span>
                            </Col>
                            <Col className="text-right">
                                {!auth?.username?.length ? null :
                                    <>
                                        <FontAwesomeIcon
                                            style={{ cursor: "pointer" }}
                                            className="mr-3"
                                            onClick={() => navigate("/edit-profile")}
                                            icon={faGear}
                                        />
                                        {!auth?.isUser ? null :
                                            <>
                                                <FontAwesomeIcon
                                                    style={{ cursor: "pointer" }}
                                                    className="mr-3"
                                                    onClick={() => navigate("/")}
                                                    icon={faStopwatch}
                                                />
                                                <FontAwesomeIcon
                                                    style={{ cursor: "pointer" }}
                                                    className="mr-3"
                                                    onClick={() => navigate("/new-shift")}
                                                    icon={faCalendarPlus}
                                                />
                                                <FontAwesomeIcon
                                                    style={{ cursor: "pointer" }}
                                                    className="mr-3"
                                                    onClick={() => navigate("/shifts")}
                                                    icon={faCalendarDays}
                                                />
                                            </>
                                        }
                                        <FontAwesomeIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                Swal.fire({
                                                    icon: "warning",
                                                    title: ATTENTION[language],
                                                    text: CONFIRM_LOGOUT[language],
                                                    showCancelButton: true,
                                                    confirmButtonText: YES[language],
                                                    cancelButtonText: NO[language],
                                                    reverseButtons: true,
                                                  }).then(async (result) => {
                                                    if (result.isConfirmed) sendLogout()
                                                  })
                                            }}
                                            icon={faRightFromBracket}
                                        />
                                    </>
                                }
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Outlet />
                    </CardBody>
                </Card>
                <Row className="mb-6">
                    <Col className="text-center">
                        <a target="_blank" href={domainEnd === 'ee'
                            ? "https://www.vellaware.ee"
                            : "https://www.vellaware.com"
                        }>
                            <u>{DEVELOPED_BY[language]}</u>
                        </a>
                    </Col>
                </Row>
            </Col>
            <Col />
        </Row>
    </>
}

export default Layout
