import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../../../app/api/apiSlice"
import { createSlice } from '@reduxjs/toolkit'

const companyUserSlice = createSlice({
    name: 'companyUser',
    initialState: { selectedCompanyUser: null },
    reducers: {
        setSelectedCompanyUser: (state, action) => {
            state.selectedCompanyUser = action.payload
        }
    }
})

export const { setSelectedCompanyUser } = companyUserSlice.actions
export const companyUserReducer = companyUserSlice.reducer

const companyUsersAdapter = createEntityAdapter({})

const initialState = companyUsersAdapter.getInitialState()

export const companyUsersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCompanyUsers: builder.query({
            query: (companyId) => ({
                url: `/users/companyusers/${companyId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedCompanyUsers = responseData.map(user => {
                    user.id = user._id
                    return user
                })
                return companyUsersAdapter.setAll(initialState, loadedCompanyUsers)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'User', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'User', id }))
                    ]
                } else return [{ type: 'User', id: 'LIST' }]
            }
        }),
    }),
})

export const {
    useGetCompanyUsersQuery,
} = companyUsersApiSlice

export const selectCompanyUsersResult = companyUsersApiSlice.endpoints.getCompanyUsers.select()

const selectCompanyUsersData = createSelector(
    selectCompanyUsersResult,
    companyUsersResult => companyUsersResult.data
)

export const {
    selectAll: selectAllCompanyUsers,
    selectById: selectCompanyUserById,
    selectIds: selectCompanyUserIds
} = companyUsersAdapter.getSelectors(state => selectCompanyUsersData(state) ?? initialState)
